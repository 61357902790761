.settings {
  input {
    font-family: 'Exo 2', sans-serif;
    font-weight: 100;
    font-size: 14px;
    line-height: 24px;
    text-indent: 0;
    text-align: center;
    width: 100%;
    height: 24px;
    padding: 24px 0 22px 0;
    border: none;
    float: left;
    color: #ffffff;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -khtml-border-radius: 10px;
    background: #293039;
  }

  label {
    padding-top: 16px;
  }

  &__item {
    display: flex;
    margin-top: 30px;
  }

  .btn-save {
    margin-top: 36px;
    padding: 0 15px;
  }

  .pin-code {
    input {
      padding-left: 0 !important;
    }
  }
}

@media (max-width: 720px) {
  .settings {
    &__item {
      flex-direction: column;
    }
  }
}
