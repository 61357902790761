.receive-tab {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    margin-top: 30px;
    font-size: 14px;
  }

  &__address {
    margin: 12px 0 0 0 !important;
    width: auto !important;
  }
}
