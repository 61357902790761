.delegate {

  label {
    color: #181818;
  }

  input {
    width: 600px;
  }
}

.delegate_dark {
  label {
    color: #FFFFFF;
  }

  input {
    background: #293039 !important;
    color: #FFFFFF;
  }
}

.ReactModal__Content {
  @media (max-width: 720px) {
    width: 480px !important;

    input {
      width: 100%;
      margin: 20px 0;
      box-sizing: border-box;
    }
  }

  @media (max-width: 560px) {
    width: 320px !important;

    input {
      width: 100%;
      margin: 20px 0;
      box-sizing: border-box;
    }

    button {
      width: 100%;
    }
  }
}
