.daterangepicker {
  background: #19202a;
  border: 1px solid #242a34;
  -moz-box-shadow: 0 15px 25px 0 rgba(0,0,0,0.35);
  -webkit-box-shadow: 0 15px 25px 0 rgba(0,0,0,0.35);
  box-shadow: 0 15px 25px 0 rgba(0,0,0,0.35);
  border-radius: 4px;
}

.daterangepicker .left, .daterangepicker .right {
  padding-right: 32px !important;
}

.calendar-table {
  border: none !important;
}

.table-condensed thead tr:nth-child(2) {
  color: #43a260;
}

.drp-calendar, .calendar-table, .off {
  background: rgb(25, 32, 42) !important;
  color: #FFFFFF;
}

.available:hover {
  color: #000000 !important;
}

th.prev.available:hover, th.next.available:hover {
  background: #554df0 !important;
}

.prev span, .next span {
  color: #000000 !important;
  border: solid #FFFFFF !important;
  border-width: 0 2px 2px 0 !important;
  border-radius: 0;
  display: inline-block;
  padding: 3px;
}

td.available:hover {
  background: #357ebd !important;
  color: #FFFFFF !important;
}

.daterangepicker.opensright:after, .daterangepicker.opensright:before {
  display: none;
}

.drp-selected {
  color: #FFFFFF !important;
}

.show-more-btn {
  display: flex;
  justify-content: center;

  .show-more {
    width: calc(33.33% - 30px);
    margin: 20px 0;
  }
}

.transactions-error-msg {
  margin-top: 30px;
  margin-left: 15px;
  color: #000;
}