.submit-label {
  color: #181818;
  transition: opacity 1s ease-out;
  opacity: 0;
  height: 0;
  overflow: hidden;

  &_visible {
    opacity: 1;
    height: auto;
  }
}