$block-background: #19202a

.box-6
  p
    div
      max-width: 80%

#page[data-theme="dark"]
  .MuiToolbar-root, .MuiTable-root, .MuiTableCell-head, .MuiTableSortLabel-root:hover,
  .MuiTableSortLabel-root.MuiTableSortLabel-active, .card-header, .delegation-info__item,
  .label, .ReactModal__Content--after-open, .form-control, .validator-header
    background: #19202a !important
    color: #FFFFFF !important
  .MuiInputBase-input, .MuiSvgIcon-root
    color: #FFFFFF !important
  .MuiTableCell-root, .identity, .col-sm-8
    a
      background: #19202a !important
      color: #FFFFFF !important
  .delegate
    label
      background: #19202a !important
      color: #FFFFFF !important
  .form-group
    label
      background: #19202a !important
      color: #FFFFFF !important

  .col-md-8, .col-md-4, .validator-logo, .validator-container, .col-12
    .card-body
      background: #19202a !important
      color: #FFFFFF !important
  .mnemo-btn-lg.btn-secondary, .btn-bg.btn-secondary, .copy-block
    color: #000000
  .submit-label
    color: #ffffff
  .preloader__content_bg div
    border: 12px solid #ffffff
    border-top-color: transparent
  .transactions-error-msg, .main-page-error-msg
    color: #FFF
@media all and (max-width: 720px)
  .box-6
    p
      div
        max-width: 100%


