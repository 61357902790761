.main-page-error-msg {
  margin-top: 20px;
  text-align: center;
  color: #000;
}

.hash-link {
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  color: white;
}
