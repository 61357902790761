.validator {
  margin-top: 30px;
  color: #181818 !important;

  &-container {
    margin-left: 15px;
    width: 66.666%;
  }


  &-logo {
    margin-right: 15px;
    width: 33.333333%;
  }

  &-info {
    &__item {
      display: flex;
      word-break: break-word;

      .label {
        color: #181818;
        font-weight: 700;
        font-size: 14px;
        text-align: left;
      }
    }

    &__tokens {
      font-size: 3.5rem;
      font-weight: 300;
      line-height: 1.2;
    }
  }

  &-balance {

    .badge {
      margin: 20px 0;
      font-size: 3.5rem !important;
    }
  }

  .row {
    display: flex;
  }


  .col-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .col-sm-4 {
    width: 33.33333333%;
  }

  .col-sm-8 {
    width: 66.66666667%;
  }

  .card {
    background-color: #fff;

    &-header {
      padding: 12px 20px;
      color: #181818 !important;

    }

    &-body {
      padding: 20px;
      color: #181818 !important;

    }
  }

  .rounded-circle {
    border-radius: 50% !important;
  }

  .delegation {
    display: flex;
    flex-direction: column;

    &-info {
      &__item {
        display: flex;
      }
    }

    .label {
      color: #181818;
      font-weight: 700;
      font-size: 14px;
      text-align: left;
    }

    &-btns {
      display: flex;
      margin-top: 20px;

      button {
        margin: 0 10px;
      }
    }
  }

  @media (max-width: 1020px) {

    .validator-logo {
      margin-right: 0;
      width: 100%;
    }

    .validator-container {
      margin-left: 0;
      width: 100%;
    }

    .row {
      display: flex;
      flex-direction: column;
    }
  }

  @media (max-width: 720px) {
    .validator-balance {
      .badge {
        font-size: 36px !important;
      }
    }

    .validator-info__tokens {
      white-space: normal;
    }
    .delegation-btns {
      flex-direction: column;

      button {
        width: 100%;
        margin: 10px 0;
      }
    }
  }

  @media (max-width: 480px) {
    .delegation-btns {
      flex-direction: column;

      .delegators-btns-controls {
        margin: 10px 0;
      }

      button {
        flex: auto !important;
        width: 100%;
      }
    }
  }
}
