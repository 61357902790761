
/* font */

@import url('https://fonts.googleapis.com/css?family=Exo+2:100,300,400,500,700&display=swap&subset=cyrillic');

@font-face {
  font-family: "digital-7-italic";
  src: url('/assets/fonts/digital-7-italic.eot');
  src: url('/assets/fonts/digital-7-italic.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/digital-7-italic.otf') format('opentype'),
  url('/assets/fonts/digital-7-italic.svg'),
  url('/assets/fonts/digital-7-italic.woff') format('woff'),
  url('/assets/fonts/digital-7-italic.ttf') format('truetype');
}
