$page-background: #eff1f3
$block-background: #ffffff
$text-color: #181818
$input-color: #eff1f3
$inactive-color: #bababa

#page[data-theme="light"]
  background: $page-background

  #nav
    a
      background: $block-background

  .box-4-l
    background: $block-background

    div
      color: $text-color

  body
    background: $page-background

  ::-webkit-scrollbar-thumb, ::selection, ::-moz-selection
    background: $page-background

  a:link, a:visited, a:hover, a:active
    color: $text-color

  .box-4-l .formbox input, .box-3-l .formbox input, .box-2-b .formbox input, .box-1-b .formbox input
    background: $input-color
    color: $text-color

  .box-4-l .formbox input.email
    background: url('../img/15-white.png') no-repeat center right $input-color

  .box-4-l .formbox input.password
    background: url('../img/16-white.png') no-repeat center right $input-color

  .box-4-l .formbox input.error.email
    background: url('../img/15-2.png') no-repeat center right $input-color

  .box-4-l .formbox input.error.password
    background: url('../img/16-2.png') no-repeat center right $input-color

  .box-4-l .formbox input:-moz-placeholder, .box-3-l .formbox input:-moz-placeholder, .box-2-b .formbox input:-moz-placeholder, .box-1-b .formbox input:-moz-placeholder
    color: $text-color

  .box-4-l .formbox input::-webkit-input-placeholder, .box-3-l .formbox input::-webkit-input-placeholder, .box-2-b .formbox input::-webkit-input-placeholder, .box-1-b .formbox input::-webkit-input-placeholder
    color: $text-color

  .box-4-l h2, .box-4-l h6, .box-4-l p
    color: $text-color

  .box-4-r
    background: url('../img/13.png') repeat-y center left $block-background

  .box-4-r p
    color: $text-color

  .box-3 h2, .box-3-l h3, .box-3-r h3, .box-2-t h2, .box-2-t h3, .box-2-b h2, .box-5-w h2, .box-1-l h2, .box-1-r h2
    color: $text-color

  a.new-transfer-button
    color: $text-color

  .box-5-nav-active, .more-button, .sp1-1, .sp1-2
    color: $text-color

  #box-4-w a.logo
    color: $text-color!important
    background: url('../img/logo-2-white.png') no-repeat top center


  #top
    background: $block-background

    h2, p
      color: $text-color

  .box-1-r
    a.transaction-button
      color: #ffffff
      background: #554df0


    .transaction, .structure
      background: $block-background

    .structure
      .str-p-1, .str-p-2, .str-p-3
        color: $text-color

    h3, p, span
      color: $text-color

    h3, p
      span
        color: $text-color

  .box-1-b
    .formbox
      border-top: none

    .formbox, a
      background: $block-background

    h5, p
      color: $text-color
    a
      border-bottom: 3px solid $block-background
      text-decoration: none

    a.active
      background-color: #554df0
      border-bottom: 3px solid #554df0
      color: #ffffff

  .box-5
    background: url('../img/dark-shadow.png') repeat-x center 70px $block-background

    span
      color: $text-color

  .p5-1, .p5-2, .p5-3
    color: $text-color

    span
      color: $text-color

  .box-5-nav
    background: $block-background
    color: $text-color

  .box-5-nav-active
    background: #554df0
    color: #ffffff

  .box-3-l
    background: $block-background

    .p3-1, .p3-2
      color: $text-color

  .box-3-r
    background: $block-background

    .p3-3
      color: $text-color

      span
        color: $text-color

  .box-3-b
    .p3-4
      background: $block-background
      color: $text-color

      span
        color: $text-color

  .settings
    background: $block-background

    label
      color: $text-color

    input
      background: $input-color
      color: $text-color

  .box-6
    background: $block-background

    p
      color: $text-color

  .box-6-w h2
    color: $text-color

  .graph
    background: $block-background

  .box-1-r .structure::after
    background: url('../img/9-white.png') no-repeat bottom center

  #nav a.nav-1::after
    background: url('../img/1-white.png') no-repeat center center

  #nav a.nav-2::after
    background: url('../img/2-white.png') no-repeat center center

  #nav a.nav-3::after
    background: url('../img/3-white.png') no-repeat center center

  #nav a.nav-4::after
    background: url('../img/4-white.png') no-repeat center center

  #nav a.nav-5::after
    background: url('../img/46.png') no-repeat center center

  #nav a.nav-6::after
    background: url('../img/validator-white.png') no-repeat center center

  #nav a.nav-1:hover::after
    background: url('../img/1-white.png') no-repeat center center #554df0

  #nav a.nav-2:hover::after
    background: url('../img/2-white.png') no-repeat center center #554df0

  #nav a.nav-3:hover::after
    background: url('../img/3-white.png') no-repeat center center #554df0

  #nav a.nav-4:hover::after
    background: url('../img/4-white.png') no-repeat center center #554df0

  #nav a.nav-5:hover::after
    background: url('../img/46.png') no-repeat center center #554df0

  #nav a.nav-6:hover::after
    background: url('../img/validator-white.png') no-repeat center center #554df0

  .box-3-l .formbox
    background: url('../img/dark-shadow.png') repeat-x top center

  .p3-4-1::after
    background: url('../img/26-white.png') no-repeat top center

  .p3-4-2::after
    background: url('../img/27-white.png') no-repeat top center

  .p3-4-3::after
    background: url('../img/28-white.png') no-repeat top center

  .p3-4-4::after
    background: url('../img/29.png') no-repeat top center

  .box-7-1
    background: url('../images/44.png') no-repeat top center $block-background

  .box-7-2
    background: url('../images/44.png') no-repeat top center $block-background
  .box-7-3
    background: url('../images/45.png') no-repeat top center $block-background
  .box-7-4
    background: url('../images/46.png') no-repeat top center $block-background

  .box-7, .box-7-5, .box-7-6
    p
      color: $text-color

  .box-7-5
    background: url('../images/40.png') repeat top left $block-background

  .box-7-6
    background: $block-background

  @media all and (max-width: 720px)
    .box-6
      .p6-2
        color: #ffffff



