/* Large Devices, Wide Screens */


@media only screen and (max-width : 1200px)
	html, body
		min-width: 1000px

	#nav-w
		width: 80px
		max-width: 1920px
		padding: 0
		margin: 0 0 0 -480px
		position: absolute
		top: 0
		left: 50%
		z-index: 90

	#box-3-w
		width: 100%
		padding: 0
		margin: 0
		position: relative
		min-height: 770px

	#box-3
		width: 850px
		padding: 30px 0 50px 110px
		margin: 0 auto
		position: relative
		text-align: left
		z-index: 5

	.box-3 h2
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: left
		font-size: 20px
		line-height: 24px
		min-height: 50px
		padding: 30px 0 0 0
		margin: 30px 0 0 0
		color: #ffffff
		cursor: default

	.box-3-l
		width: calc(50% - 75px)
		padding: 30px 30px 30px 30px
		margin: 30px 15px 0 0
		position: relative
		text-align: left
		float: left
		background: #19202a
		border-radius: 10px
		-webkit-border-radius: 10px
		-moz-border-radius: 10px
		-khtml-border-radius: 10px

	.box-3-l h3
		font-family:  'Exo 2', sans-serif
		font-weight: 500
		font-style: normal
		text-align: left
		font-size: 14px
		line-height: 24px
		padding: 0
		margin: 0
		color: #ffffff
		cursor: default

	.p3-1
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: left
		font-size: 14px
		line-height: 24px
		padding: 0
		margin: 5px 0 0 0
		color: rgba(255,255,255,0.5)
		cursor: default

	.p3-1 br
		display: none

	.p3-2
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: left
		font-size: 14px
		line-height: 24px
		padding: 0
		margin: 5px 0 0 0
		color: rgba(255,255,255,1)
		cursor: default

	.box-3-l .formbox
		width: 100%
		margin: 30px 0 0 -30px
		padding: 35px 30px 0 30px
		text-align: center
		position: relative
		background: url('../img/19.png') repeat-x top center

	.box-3-l .formbox input
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-size: 14px
		line-height: 24px
		text-indent: 0
		text-align: center
		width: calc(50% - 75px)
		height: 24px
		padding: 19px 30px 17px 30px
		border: none
		margin: 0 30px 0 0
		float: left
		color: #ffffff
		outline: none
		-webkit-appearance:  none
		-moz-appearance: none
		border-radius: 10px
		-webkit-border-radius: 10px
		-moz-border-radius: 10px
		-khtml-border-radius: 10px
		background: #293039

	.box-3-l .formbox input:-moz-placeholder
		color: #ffffff

	.box-3-l .formbox input::-webkit-input-placeholder
		color: #ffffff

	.box-3-l .formbox input.error:-moz-placeholder
		color: #f24d4d

	.box-3-l .formbox input.error::-webkit-input-placeholder
		color: #f24d4d

	.box-3-l .formbox input.error
		color: #f24d4d
		border-color: #f24d4d

	input::-webkit-input-placeholder
		opacity: 1
		transition: opacity 0.3s ease

	input::-moz-placeholder
		opacity: 1
		transition: opacity 0.3s ease

	input:-moz-placeholder
		opacity: 1
		transition: opacity 0.3s ease

	input:-ms-input-placeholder
		opacity: 1
		transition: opacity 0.3s ease

	input:focus::-webkit-input-placeholder
		opacity:  0.3
		transition: opacity 0.3s ease

	input:focus::-moz-placeholder
		opacity:  0.3
		transition: opacity 0.3s ease

	input:focus:-moz-placeholder
		opacity:  0.3
		transition: opacity 0.3s ease

	input:focus:-ms-input-placeholder
		opacity:  0.3
		transition: opacity 0.3s ease


	.box-3-l .formbox .submit
		width: calc(50% - 15px)
		height: 60px
		padding: 0
		margin: 0
		float: left
		border: none
		outline: none
		position: relative

	.box-3-l .formbox .submit button
		font-family:  'Exo 2', sans-serif
		font-weight: 500
		font-style: normal
		text-align: center
		text-transform: uppercase
		font-size: 14px
		line-height: 62px
		width: 100%
		height: 60px
		padding: 0
		margin: 0
		outline: none
		cursor: pointer
		-webkit-appearance:  none
		-moz-appearance: none
		color: #ffffff
		border: none
		background: #554df0
		border-radius: 10px
		-webkit-border-radius: 10px
		-moz-border-radius: 10px
		-khtml-border-radius: 10px
		-moz-box-shadow:  0 10px 20px 0 rgba(85,77,240,0.25)
		-webkit-box-shadow:  0 10px 20px 0 rgba(85,77,240,0.25)
		box-shadow:  0 10px 20px 0 rgba(85,77,240,0.25)

	.box-3-l .formbox .submit button:hover
		background: #3e35df
		-moz-box-shadow:  0 20px 40px 0 rgba(85,77,240,0.35)
		-webkit-box-shadow:  0 20px 40px 0 rgba(85,77,240,0.35)
		box-shadow:  0 20px 40px 0 rgba(85,77,240,0.35)
		outline: none

	.formbox .submit button:hover, .formbox .submit button:not(:hover)
		-webkit-transition:  All 0.2s ease
		-moz-transition:  All 0.2s ease
		-o-transition:  All 0.2s ease
		-ms-transition:  All 0.2s ease
		transition:  All 0.2s ease

	.box-3-r
		width: calc(50% - 75px)
		padding: 30px 30px 30px 30px
		margin: 30px 0 0 15px
		position: relative
		text-align: left
		float: left
		background: #19202a
		border-radius: 10px
		-webkit-border-radius: 10px
		-moz-border-radius: 10px
		-khtml-border-radius: 10px

	.box-3-r h3
		font-family:  'Exo 2', sans-serif
		font-weight: 500
		font-style: normal
		text-align: left
		font-size: 14px
		line-height: 24px
		padding: 0
		margin: 0
		color: #ffffff
		cursor: default

	.p3-3
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: left
		font-size: 14px
		line-height: 20px
		min-height: 36px
		padding: 0 0 0 50px
		color: rgba(255,255,255,0.5)
		cursor: default
		position: relative
		width: calc(100% - 50px)
		float: left

	.p3-3-1
		margin: 15px 0 0 0

	.p3-3-2
		margin: 10px 0 0 0

	.p3-3-3
		margin: 10px 0 0 0

	.p3-3-4
		margin: 10px 0 0 0

	.p3-3-5
		margin: 10px 0 0 0

	.p3-3 span
		color: rgba(255,255,255,1)

	.p3-3::after
		content: ''
		width: 36px
		height: 36px
		padding: 0
		margin: 0
		cursor: default
		-moz-box-shadow:  0 10px 20px 0 rgba(85,77,240,0.25)
		-webkit-box-shadow:  0 10px 20px 0 rgba(85,77,240,0.25)
		box-shadow:  0 10px 20px 0 rgba(85,77,240,0.25)
		border-radius: 100%
		-webkit-border-radius: 100%
		-moz-border-radius: 100%
		-khtml-border-radius: 100%
		position: absolute
		top: 2px
		left: 0
		z-index: auto

	.p3-3-1::after
		background: url('../img/22.png') no-repeat center center #554df0

	.p3-3-2::after
		background: url('../img/22.png') no-repeat center center #554df0

	.p3-3-3::after
		background: url('../img/23.png') no-repeat center center #554df0

	.p3-3-4::after
		background: url('../img/24.png') no-repeat center center #554df0

	.p3-3-5::after
		background: url('../img/25.png') no-repeat center center #554df0

	.box-3-b
		width: calc(100% + 30px)
		padding: 0
		margin: 0 0 0 -15px
		position: relative
		text-align: left

	.p3-4
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: center
		font-size: 14px
		line-height: 24px
		min-height: 36px
		width: calc(50% - 30px)
		padding: 35px 0 35px 0
		margin: 30px 15px 0 15px
		color: rgba(255,255,255,0.5)
		background: #19202a
		border-radius: 10px
		-webkit-border-radius: 10px
		-moz-border-radius: 10px
		-khtml-border-radius: 10px
		cursor: default
		position: relative
		float: left

	.sp3-1
		font-family:  'Exo 2', sans-serif
		font-weight: 500
		font-style: normal
		text-align: center
		text-transform: uppercase
		font-size: 20px
		line-height: 24px
		padding: 0
		margin: 5px 0 0 0
		color: rgba(255,255,255,1)
		display: inline-block

	.sp3-2
		font-family:  'Exo 2', sans-serif
		font-weight: 100

	.sp3-3
		font-family:  "digital-7-italic"
		font-weight: normal
		font-style: normal
		text-align: center
		text-transform: uppercase
		font-size: 32px
		line-height: 24px
		padding: 0
		margin: 90px 0 0 0
		color: rgba(255,255,255,1)
		display: inline-block

	.p3-4::after
		content: ''
		width: 100%
		height: 130px
		padding: 0
		margin: 0
		cursor: default
		position: absolute
		left: 0
		bottom: 0
		z-index: auto

	.p3-4-1::after
		background: url('../img/26.png') no-repeat top center

	.p3-4-2::after
		background: url('../img/27.png') no-repeat top center

	.p3-4-3::after
		background: url('../img/28.png') no-repeat top center

	.p3-4-4::after
		background: url('../img/29.png') no-repeat top center

	#box-2-w
		width: 100%
		padding: 0
		margin: 0
		position: relative

	.br960
		display: none

	#box-2
		width: 850px
		padding: 30px 0 50px 110px
		margin: 0 auto
		position: relative
		text-align: left
		z-index: 5

	.box-2-t
		width: calc(100% - 60px)
		padding: 30px 30px 40px 30px
		margin: 30px 0 0 0
		position: relative
		text-align: left
		background: #19202a
		border-top-right-radius: 10px
		-webkit-border-top-right-radius: 10px
		-moz-border-top-right-radius: 10px
		-khtml-border-top-right-radius: 10px
		border-top-left-radius: 10px
		-webkit-border-top-left-radius: 10px
		-moz-border-top-left-radius: 10px
		-khtml-border-top-left-radius: 10px

	.box-2-t h2
		font-family: 'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: left
		font-size: 20px
		line-height: 24px
		padding: 0
		margin: 0 0 10px 0
		color: #ffffff
		cursor: default

	.p2-1
		font-family: 'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: left
		font-size: 14px
		line-height: 24px
		padding: 0
		margin: 0
		color: rgba(255,255,255,0.5)
		cursor: default

	.box-2-t h3
		font-family: 'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: left
		font-size: 20px
		line-height: 24px
		padding: 0
		margin: 20px 0 0 0
		color: #ffffff
		cursor: default

	.p2-2
		font-family: 'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: left
		font-size: 14px
		line-height: 24px
		width: calc(50% - 90px)
		padding: 25px 30px 25px 30px
		margin: 40px 0 0 15px
		color: rgba(255,255,255,1)
		border: 1px solid #252b35
		border-radius: 10px
		-webkit-border-radius: 10px
		-moz-border-radius: 10px
		-khtml-border-radius: 10px
		cursor: default
		position: relative

	.p2-2-1
		float: left
		min-height: 120px

	.p2-2-2
		float: right
		min-height: 120px

	.p2-2-3
		float: left
		min-height: 100px

	.p2-2-4
		float: right
		min-height: 100px

	.p2-2 a.appstore
		width: 108px
		height: 33px
		padding: 0
		margin: 5px 0 0 0
		background: url('../img/17.png') no-repeat
		border-radius: 5px
		-webkit-border-radius: 5px
		-moz-border-radius: 5px
		-khtml-border-radius: 5px
		cursor: pointer
		position: relative
		display: inline-block

	.p2-2 a.googleplay
		width: 108px
		height: 33px
		padding: 0
		margin: 5px 0 0 10px
		background: url('../img/18.png') no-repeat
		border-radius: 5px
		-webkit-border-radius: 5px
		-moz-border-radius: 5px
		-khtml-border-radius: 5px
		cursor: pointer
		position: relative
		display: inline-block

	.p2-2 a.appstore:hover,
	.p2-2 a.googleplay:hover
		opacity: 0.5

	.sp2-1
		font-family: 'Exo 2', sans-serif
		font-weight: 500
		font-style: normal
		text-align: center
		font-size: 12px
		line-height: 32px
		width: 30px
		height: 30px
		padding: 0
		margin: 0
		color: rgba(255,255,255,1)
		background: #554df0
		border-radius: 2px
		-webkit-border-radius: 2px
		-moz-border-radius: 2px
		-khtml-border-radius: 2px
		cursor: default
		position: absolute
		top: -15px
		left: -15px
		z-index: auto

	.sp2-2
		font-family: 'Exo 2', sans-serif
		font-weight: 500

	.sp2-3
		font-family: 'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: left
		font-size: 13px
		line-height: 22px
		padding: 0
		margin: 5px 0 0 0
		color: rgba(255,255,255,0.5)
		display: inline-block

	.box-2-b
		width: calc(100% - 60px)
		padding: 40px 30px 50px 30px
		margin: 0
		position: relative
		text-align: left
		background: url('../img/19.png') repeat-x top center #19202a
		border-bottom-right-radius: 10px
		-webkit-border-bottom-right-radius: 10px
		-moz-border-bottom-right-radius: 10px
		-khtml-border-bottom-right-radius: 10px
		border-bottom-left-radius: 10px
		-webkit-border-bottom-left-radius: 10px
		-moz-border-bottom-left-radius: 10px
		-khtml-border-bottom-left-radius: 10px

	.box-2-b h2
		font-family: 'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: left
		font-size: 20px
		line-height: 24px
		padding: 0
		margin: 0
		color: #ffffff
		cursor: default

	.box-2-b .formbox
		width: 450px
		margin: 0
		padding: 0
		text-align: center
		position: relative

	.box-2-b .formbox input
		font-family: 'Exo 2', sans-serif
		font-weight: 100
		font-size: 14px
		line-height: 24px
		text-indent: 0
		text-align: center
		width: 150px
		height: 24px
		padding: 24px 30px 22px 30px
		border: none
		margin: 20px 30px 0 0
		float: left
		color: #ffffff
		outline: none
		-webkit-appearance:  none
		-moz-appearance: none
		border-radius: 10px
		-webkit-border-radius: 10px
		-moz-border-radius: 10px
		-khtml-border-radius: 10px
		background: #293039

	.box-2-b .formbox input:-moz-placeholder
		color: #ffffff

	.box-2-b .formbox input::-webkit-input-placeholder
		color: #ffffff

	.box-2-b .formbox input.error:-moz-placeholder
		color: #f24d4d

	.box-2-b .formbox input.error::-webkit-input-placeholder
		color: #f24d4d

	.box-2-b .formbox input.error
		color: #f24d4d
		border-color: #f24d4d

	input::-webkit-input-placeholder
		opacity:  1
		transition: opacity 0.3s ease

	input::-moz-placeholder
		opacity:  1
		transition: opacity 0.3s ease

	input:-moz-placeholder
		opacity:  1
		transition: opacity 0.3s ease

	input:-ms-input-placeholder
		opacity:  1
		transition: opacity 0.3s ease

	input:focus::-webkit-input-placeholder
		opacity:  0.3
		transition: opacity 0.3s ease

	input:focus::-moz-placeholder
		opacity:  0.3
		transition: opacity 0.3s ease

	input:focus:-moz-placeholder
		opacity:  0.3
		transition: opacity 0.3s ease

	input:focus:-ms-input-placeholder
		opacity:  0.3
		transition: opacity 0.3s ease

	.box-2-b .formbox .submit
		width: 210px
		height: 70px
		padding: 0
		margin: 20px 0 0 0
		float: left
		border: none
		outline: none
		position: relative

	.box-2-b .formbox .submit button
		font-family: 'Exo 2', sans-serif
		font-weight: 500
		font-style: normal
		text-align: center
		text-transform: uppercase
		font-size: 14px
		line-height: 72px
		width: 100%
		height: 70px
		padding: 0
		margin: 0
		outline: none
		cursor: pointer
		-webkit-appearance:  none
		-moz-appearance: none
		color: #ffffff
		border: none
		background: #554df0
		border-radius: 10px
		-webkit-border-radius: 10px
		-moz-border-radius: 10px
		-khtml-border-radius: 10px
		-moz-box-shadow:  0 10px 20px 0 rgba(85,77,240,0.25)
		-webkit-box-shadow:  0 10px 20px 0 rgba(85,77,240,0.25)
		box-shadow:  0 10px 20px 0 rgba(85,77,240,0.25)

	.box-2-b .formbox .submit button:hover
		background: #3e35df
		-moz-box-shadow:  0 20px 40px 0 rgba(85,77,240,0.35)
		-webkit-box-shadow:  0 20px 40px 0 rgba(85,77,240,0.35)
		box-shadow:  0 20px 40px 0 rgba(85,77,240,0.35)
		outline: none

	.formbox .submit button:hover, .formbox .submit button:not(:hover)
		-webkit-transition: All 0.2s ease
		-moz-transition: All 0.2s ease
		-o-transition: All 0.2s ease
		-ms-transition: All 0.2s ease
		transition: All 0.2s ease

	.p2-3
		font-family: 'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: left
		font-size: 14px
		line-height: 24px
		min-height: 122px
		padding: 50px 0 0 210px
		margin: 0
		color: rgba(255,255,255,0.3)
		background: url('../img/20.png') no-repeat 0 0
		cursor: default
		position: absolute
		left: 510px
		bottom: 0
		z-index: auto

	.p2-3::after
		content: ''
		width: 30px
		height: 30px
		padding: 0
		margin: 0
		background: url('../img/21.png') no-repeat center center
		cursor: default
		position: absolute
		top: 25px
		left: 190px
		z-index: auto


	#box-5-w
		width: 100%
		padding: 0
		margin: 0
		position: relative

	#box-5
		width: 850px
		padding: 30px 0 50px 110px
		margin: 0 auto
		position: relative
		text-align: left
		z-index: 5

	.box-5-w
		width: 100%
		padding: 0
		margin: 0
		position: relative
		text-align: left

	.box-5-w h2
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: left
		font-size: 20px
		line-height: 36px
		padding: 0
		margin: 30px 0 0 0
		color: #ffffff
		cursor: default
		float: left

	a.new-transfer-button
		font-family:  'Exo 2', sans-serif
		font-weight: 500
		font-style: normal
		text-align: left
		text-transform: uppercase
		font-size: 13px
		line-height: 36px
		padding: 0 0 0 50px
		margin: 30px 0 0 0
		color: #ffffff
		cursor: pointer
		float: right
		position: relative

	a.new-transfer-button:hover
		color: #554df0

	a.new-transfer-button::after
		content: ''
		width: 36px
		height: 36px
		padding: 0
		margin: 0
		cursor: pointer
		background: url('../img/31.png') no-repeat center center #554df0
		-moz-box-shadow:  0 10px 20px 0 rgba(85,77,240,0.25)
		-webkit-box-shadow:  0 10px 20px 0 rgba(85,77,240,0.25)
		box-shadow:  0 10px 20px 0 rgba(85,77,240,0.25)
		border-radius: 100%
		-webkit-border-radius: 100%
		-moz-border-radius: 100%
		-khtml-border-radius: 100%
		position: absolute
		top: 0
		left: 0
		z-index: auto

	.box-5-nav-w
		width: 100%
		padding: 0
		margin: 30px 0 0 0
		position: relative
		text-align: left

	.box-5-nav
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: center
		font-size: 14px
		line-height: 62px
		height: 60px
		padding: 0
		color: rgba(255,255,255,0.2)
		background: #19202a
		cursor: pointer
		float: left
		border-radius: 10px
		-webkit-border-radius: 10px
		-moz-border-radius: 10px
		-khtml-border-radius: 10px

	.box-5-nav-all
		width: 260px
		margin: 0 30px 0 0

	.box-5-nav-received
		width: 265px
		margin: 0 30px 0 0

	.box-5-nav-sent
		width: 265px
		margin: 0

	.box-5-nav-active
		font-family:  'Exo 2', sans-serif
		font-weight: 300
		color: #262b2e
		background: #ffffff

	.box-5-nav-date
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: center
		font-size: 14px
		line-height: 62px
		height: 60px
		padding: 0
		margin: 30px 0 0 0
		color: rgba(255,255,255,0.2)
		background: #19202a
		cursor: default
		float: right
		border-radius: 10px
		-webkit-border-radius: 10px
		-moz-border-radius: 10px
		-khtml-border-radius: 10px

	.sp-nav-5-1
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: center
		font-size: 14px
		line-height: 62px
		width: 300px
		height: 60px
		padding: 0
		margin: 0
		color: rgba(255,255,255,0.2)
		cursor: default
		float: left

	.sp-nav-5-2
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: center
		font-size: 14px
		line-height: 62px
		width: 274px
		height: 60px
		padding: 0
		margin: 0
		color: rgba(255,255,255,0.2)
		cursor: default
		float: left
		border-left: 1px solid #242a34
		position: relative

	.sp-nav-5-3
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: center
		font-size: 14px
		line-height: 62px
		width: 274px
		height: 60px
		padding: 0
		margin: 0
		color: rgba(255,255,255,0.2)
		cursor: default
		float: left
		border-left: 1px solid #242a34
		position: relative

	.box-5-nav-date input
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-size: 14px
		line-height: 62px
		text-indent: 0
		text-align: left
		width: calc(100% - 60px)
		height: 60px
		padding: 0 20px 0 0
		border: none
		margin: 0 20px
		float: left
		color: rgba(255,255,255,0.2)
		cursor: pointer
		outline: none
		-webkit-appearance:  none
		-moz-appearance: none
		background: url('../img/32.png') no-repeat center right

	.box-5-nav-date input:-moz-placeholder
		color: rgba(255,255,255,0.2)

	.box-5-nav-date input::-webkit-input-placeholder
		color: rgba(255,255,255,0.2)

	.box-5-transaction
		width: calc(100% + 30px)
		padding: 0
		margin: 0 0 0 -15px
		position: relative
		text-align: center

	.box-5
		width: calc(50% - 30px)
		min-height: 45px
		padding: 0
		margin: 30px 15px 0 15px
		position: relative
		text-align: left
		background: url('../img/19.png') repeat-x center 70px #19202a
		border-radius: 10px
		-webkit-border-radius: 10px
		-moz-border-radius: 10px
		-khtml-border-radius: 10px
		float: left

	.box-5:hover
		background: url('../img/19.png') repeat-x center 70px #202936

	.box-5:hover, .box-5:not(:hover)
		-webkit-transition:  All 0.2s ease
		-moz-transition:  All 0.2s ease
		-o-transition:  All 0.2s ease
		-ms-transition:  All 0.2s ease
		transition:  All 0.2s ease

	.box-5 h3
		font-family:  'Exo 2', sans-serif
		font-weight: 500
		font-style: normal
		text-align: left
		font-size: 14px
		line-height: 32px
		height: 30px
		padding: 20px 30px 20px 75px
		margin: 0
		position: relative

	.box-5-received h3
		color: #43a260

	.box-5-sent h3
		color: #f24d4d

	.box-5 h3 span
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: right
		font-size: 14px
		line-height: 32px
		padding: 0
		margin: 0
		color: rgba(255,255,255,0.2)
		float: right
		cursor: default
		position: relative

	.box-5-received h3
		color: #43a260

	.box-5-sent h3
		color: #f24d4d

	.box-5 h3::after
		content: ''
		width: 30px
		height: 30px
		padding: 0
		margin: 0
		position: absolute
		top: 20px
		left: 30px
		z-index: auto
		border-radius: 100%
		-webkit-border-radius: 100%
		-moz-border-radius: 100%
		-khtml-border-radius: 100%

	.box-5-received h3::after
		background: url('../img/6.png') no-repeat center center #43a260
		-moz-box-shadow:  0 10px 20px 0 rgba(67,162,96,0.25)
		-webkit-box-shadow:  0 10px 20px 0 rgba(67,162,96,0.25)
		box-shadow:  0 10px 20px 0 rgba(67,162,96,0.25)

	.box-5-sent h3::after
		background: url('../img/7.png') no-repeat center center #f24d4d
		-moz-box-shadow:  0 10px 20px 0 rgba(272,77,77,0.25)
		-webkit-box-shadow:  0 10px 20px 0 rgba(272,77,77,0.25)
		box-shadow:  0 10px 20px 0 rgba(272,77,77,0.25)

	.p5-1
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: left
		font-size: 14px
		line-height: 20px
		width: calc(50% - 30px)
		padding: 25px 0 25px 30px
		margin: 0
		color: rgba(255,255,255,0.2)
		cursor: default
		float: left

	.p5-1 span
		font-family:  'Exo 2', sans-serif
		font-weight: 500
		font-style: normal
		text-transform: uppercase
		color: rgba(255,255,255,1)
		display: inline-block

	.p5-2
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: left
		font-size: 14px
		line-height: 20px
		width: 50%
		padding: 25px 0 25px 0
		margin: 0
		color: rgba(255,255,255,0.2)
		cursor: default
		float: left

	.p5-2 span
		color: rgba(255,255,255,1)
		display: inline-block

	.p5-3
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: left
		font-size: 14px
		line-height: 20px
		width: calc(100% - 60px)
		padding: 20px 30px 25px 30px
		margin: 0
		color: rgba(255,255,255,0.2)
		cursor: default
		border-top: 1px solid rgba(255,255,255,0.05)

	.p5-3 span
		font-size: 11px
		line-height: 20px
		color: rgba(255,255,255,1)

	.box-5-all
		display: none

	.more-button
		font-family:  'Exo 2', sans-serif
		font-weight: 500
		font-style: normal
		text-align: center
		text-transform: uppercase
		font-size: 13px
		line-height: 24px
		padding: 40px 0 0 0
		margin: 50px auto 0 auto
		display: inline-block
		color: #ffffff
		cursor: pointer
		position: relative
		background: url('../img/30.png') no-repeat top center

	.more-button:hover
		color: #554df0
		background: url('../img/30-2.png') no-repeat top center

	.more-button:hover, .more-button:not(:hover)
		-webkit-transition:  All 0.2s ease
		-moz-transition:  All 0.2s ease
		-o-transition:  All 0.2s ease
		-ms-transition:  All 0.2s ease
		transition:  All 0.2s ease


	#box-1-w
		width: 100%
		padding: 0
		margin: 0
		position: relative

	#box-1
		width: 850px
		padding: 30px 0 50px 110px
		margin: 0 auto
		position: relative
		text-align: left
		z-index: 5

	.box-1-l
		width: 100%
		padding: 0
		margin: 0
		position: relative
		text-align: left
		float: left

	.balance
		width: calc(50% - 15px)
		padding: 0
		margin: 48px 30px 0 0
		position: relative
		text-align: left
		float: left

	.paramining
		width: calc(50% - 15px)
		padding: 0
		margin: 48px 0 0 0
		position: relative
		text-align: left
		float: left

	.box-1-l h2
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: left
		font-size: 14px
		line-height: 24px
		padding: 0
		margin: 0
		color: #ffffff
		cursor: default

	.sp1-1
		font-family:  'Exo 2', sans-serif
		font-weight: 500
		font-style: normal
		text-align: left
		text-transform: uppercase
		font-size: 20px
		line-height: 24px
		padding: 0
		margin: 0
		color: #ffffff
		cursor: default

	.sp1-2
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: left
		text-transform: uppercase
		font-size: 20px
		line-height: 24px
		padding: 0 20px 0 0
		margin: 0
		color: #ffffff
		cursor: default
		position: relative

	.sp1-2::before
		cursor: pointer
		content:  ""
		display:  block
		position:  absolute
		right: 0
		top: 50%
		width: 6px
		height: 6px
		border-top:  1px solid #ffffff
		border-left:  1px solid #ffffff
		-webkit-transform:  translate(-50%,-50%) rotate(-135deg)
		transform:  translate(-50%,-50%) rotate(-135deg)
		transition:  opacity .35s

	.graph
		width: 100%
		height: 278px
		overflow: hidden
		padding: 0
		margin: 40px 0 0 0
		position: relative
		text-align: left
		float: left
		background: url('../img/10.png') repeat 0 0 #19202a
		border-radius: 10px
		-webkit-border-radius: 10px
		-moz-border-radius: 10px
		-khtml-border-radius: 10px

	.graph img
		width: 100%
		max-width: 360px
		padding: 0
		margin: 0
		display: block

	.box-1-r
		width: 100%
		padding: 0
		margin: 30px 0 0 0
		position: relative
		text-align: left
		float: right

	.box-1-r h2
		font-family:  'Exo 2', sans-serif
		font-weight: 400
		font-style: normal
		text-align: left
		font-size: 18px
		line-height: 24px
		padding: 20px 0 0 0
		margin: 0
		color: #ffffff
		cursor: default

	.box-1-r .transaction
		width: calc(100% - 95px)
		min-height: 45px
		padding: 20px 20px 15px 75px
		margin: 30px 0 0 0
		position: relative
		text-align: left
		background: #19202a
		border-radius: 10px
		-webkit-border-radius: 10px
		-moz-border-radius: 10px
		-khtml-border-radius: 10px

	.box-1-r a.transaction-button
		font-family:  'Exo 2', sans-serif
		font-weight: 500
		font-style: normal
		text-align: center
		font-size: 14px
		line-height: 62px
		width: 100%
		height: 60px
		padding: 0
		margin: 30px 0 0 0
		cursor: pointer
		position: relative
		color: #151b22
		background: #ffffff
		border-radius: 10px
		-webkit-border-radius: 10px
		-moz-border-radius: 10px
		-khtml-border-radius: 10px
		display: block

	.box-1-r a.transaction-button:hover
		color: rgba(21,27,34,0.8)
		-moz-box-shadow:  0 15px 25px 0 rgba(0,0,0,0.35)
		-webkit-box-shadow:  0 15px 25px 0 rgba(0,0,0,0.35)
		box-shadow:  0 15px 25px 0 rgba(0,0,0,0.35)

	.box-1-r .transaction:hover
		background: #202936

	.transaction:hover, .transaction:not(:hover)
		-webkit-transition:  All 0.2s ease
		-moz-transition:  All 0.2s ease
		-o-transition:  All 0.2s ease
		-ms-transition:  All 0.2s ease
		transition:  All 0.2s ease

	.box-1-r .transaction::after
		content: ''
		width: 40px
		height: 40px
		padding: 0
		margin: 0
		position: absolute
		top: 20px
		left: 20px
		z-index: auto
		border-radius: 100%
		-webkit-border-radius: 100%
		-moz-border-radius: 100%
		-khtml-border-radius: 100%

	.box-1-r .transaction-received::after
		background: url('../img/6.png') no-repeat center center #43a260
		-moz-box-shadow:  0 10px 20px 0 rgba(67,162,96,0.25)
		-webkit-box-shadow:  0 10px 20px 0 rgba(67,162,96,0.25)
		box-shadow:  0 10px 20px 0 rgba(67,162,96,0.25)

	.box-1-r .transaction-sent::after
		background: url('../img/7.png') no-repeat center center #f24d4d
		-moz-box-shadow:  0 10px 20px 0 rgba(272,77,77,0.25)
		-webkit-box-shadow:  0 10px 20px 0 rgba(272,77,77,0.25)
		box-shadow:  0 10px 20px 0 rgba(272,77,77,0.25)

	.box-1-r .transaction h3
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: left
		font-size: 12px
		line-height: 20px
		padding: 0
		margin: 0
		color: rgba(255,255,255,0.5)
		cursor: default
		float: left

	.box-1-r .transaction h3 span
		font-family:  'Exo 2', sans-serif
		font-weight: 500
		font-style: normal
		text-align: left
		font-size: 14px
		line-height: 20px
		padding: 0
		margin: 0
		color: rgba(255,255,255,1)
		cursor: default

	.box-1-r .transaction p
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: right
		font-size: 12px
		line-height: 20px
		padding: 0
		margin: 0
		color: rgba(255,255,255,0.5)
		cursor: default
		float: right

	.box-1-r .transaction p span
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: right
		font-size: 14px
		line-height: 20px
		padding: 0
		margin: 0
		color: rgba(255,255,255,1)
		cursor: default

	.box-1-r .structure
		width: calc(100% - 40px)
		min-height: 45px
		padding: 20px 20px 30px 20px
		margin: 30px 0 0 0
		position: relative
		text-align: center
		background: #19202a
		border-radius: 10px
		-webkit-border-radius: 10px
		-moz-border-radius: 10px

	.box-1-r .structure::after
		content: ''
		width: 100%
		height: 100px
		padding: 0
		margin: 0
		position: absolute
		left: 0
		bottom: 30px
		z-index: auto
		background: url('../img/9.png') no-repeat bottom center

	.box-1-r .structure h3
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: center
		font-size: 14px
		line-height: 24px
		padding: 0
		margin: 0
		color: rgba(255,255,255,1)
		cursor: default

	.box-1-r .structure .str-p-1
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: center
		text-transform: uppercase
		font-size: 20px
		line-height: 24px
		padding: 0
		margin: 30px 0 0 0
		color: rgba(255,255,255,1)
		cursor: default

	.box-1-r .structure .str-p-1 span
		font-family:  'Exo 2', sans-serif
		font-weight: 500

	.box-1-r .structure .str-p-2
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: center
		text-transform: uppercase
		font-size: 14px
		line-height: 24px
		padding: 0
		margin: 0
		color: rgba(255,255,255,1)
		cursor: default

	.box-1-r .structure .str-p-2 span
		font-family:  'Exo 2', sans-serif
		font-weight: 500

	.box-1-r .structure .str-p-3
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: center
		font-size: 12px
		line-height: 18px
		padding: 35px 0 0 0
		margin: 65px 0 0 0
		background: url('../img/8.png') no-repeat top center
		color: rgba(255,255,255,0.5)
		cursor: default

	.box-1-r .structure .str-p-3 span
		font-size: 16px
		line-height: 18px

	.box-1-b
		width: 100%
		padding: 0
		margin: 30px 0 0 0
		position: relative
		text-align: left
		float: left

	.box-1-b a
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: center
		font-size: 14px
		line-height: 52px
		width: 200px
		height: 52px
		padding: 0
		margin: 0
		color: rgba(255,255,255,0.2)
		border-bottom: 3px solid #171e26
		background: #171e26
		cursor: pointer
		float: left
		border-top-right-radius: 10px
		-webkit-border-top-right-radius: 10px
		-moz-border-top-right-radius: 10px
		-khtml-border-top-right-radius: 10px
		border-top-left-radius: 10px
		-webkit-border-top-left-radius: 10px
		-moz-border-top-left-radius: 10px
		-khtml-border-top-left-radius: 10px

	.box-1-b a.active
		color: rgba(255,255,255,1)
		border-bottom: 3px solid #554df0
		background: #19202a

	.box-1-b p
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: left
		font-size: 16px
		line-height: 24px
		padding: 0
		width: calc(50% - 30px)
		margin: 53px 15px 0 15px
		color: rgba(255,255,255,1)
		cursor: default
		float: right

	.box-1-b p span
		font-family:  'Exo 2', sans-serif
		font-weight: 500

	.box-1-b .formbox
		width: calc(100% - 30px)
		margin: 0
		padding: 0 15px 30px 15px
		text-align: center
		position: relative
		border-top: 1px solid #2d3239
		background: #19202a
		border-bottom-right-radius: 10px
		-webkit-border-bottom-right-radius: 10px
		-moz-border-bottom-right-radius: 10px
		-khtml-border-bottom-right-radius: 10px
		border-bottom-left-radius: 10px
		-webkit-border-bottom-left-radius: 10px
		-moz-border-bottom-left-radius: 10px
		-khtml-border-bottom-left-radius: 10px

	.box-1-b .formbox input
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-size: 14px
		line-height: 24px
		text-indent: 0
		text-align: center
		width: calc(50% - 90px)
		height: 24px
		padding: 24px 30px 22px 30px
		border: none
		margin: 30px 15px 0 15px
		float: left
		color: #ffffff
		outline: none
		-webkit-appearance:  none
		-moz-appearance: none
		border-radius: 10px
		-webkit-border-radius: 10px
		-moz-border-radius: 10px
		-khtml-border-radius: 10px
		background: #293039

	.box-1-b .formbox input:-moz-placeholder
		color: #ffffff

	.box-1-b .formbox input::-webkit-input-placeholder
		color: #ffffff

	.box-1-b .formbox input.error:-moz-placeholder
		color: #f24d4d

	.box-1-b .formbox input.error::-webkit-input-placeholder
		color: #f24d4d

	.box-1-b .formbox input.error
		color: #f24d4d
		border-color: #f24d4d

	input::-webkit-input-placeholder
		opacity:  1
		transition: opacity 0.3s ease

	input::-moz-placeholder
		opacity:  1
		transition: opacity 0.3s ease

	input:-moz-placeholder
		opacity:  1
		transition: opacity 0.3s ease

	input:-ms-input-placeholder
		opacity:  1
		transition: opacity 0.3s ease

	input:focus::-webkit-input-placeholder
		opacity:  0.3
		transition: opacity 0.3s ease

	input:focus::-moz-placeholder
		opacity:  0.3
		transition: opacity 0.3s ease

	input:focus:-moz-placeholder
		opacity:  0.3
		transition: opacity 0.3s ease

	input:focus:-ms-input-placeholder
		opacity:  0.3
		transition: opacity 0.3s ease

	.box-1-b .formbox .submit
		width: calc(50% - 30px)
		height: 70px
		padding: 0
		margin: 30px 15px 0 15px
		float: left
		border: none
		outline: none
		position: relative

	.box-1-b .formbox .submit button
		font-family:  'Exo 2', sans-serif
		font-weight: 500
		font-style: normal
		text-align: center
		text-transform: uppercase
		font-size: 14px
		line-height: 72px
		width: 100%
		height: 70px
		padding: 0
		margin: 0
		outline: none
		cursor: pointer
		-webkit-appearance:  none
		-moz-appearance: none
		color: #ffffff
		border: none
		background: #554df0
		border-radius: 10px
		-webkit-border-radius: 10px
		-moz-border-radius: 10px
		-khtml-border-radius: 10px
		-moz-box-shadow:  0 10px 20px 0 rgba(85,77,240,0.25)
		-webkit-box-shadow:  0 10px 20px 0 rgba(85,77,240,0.25)
		box-shadow:  0 10px 20px 0 rgba(85,77,240,0.25)

	.box-1-b .formbox .submit button:hover
		background: #3e35df
		-moz-box-shadow:  0 20px 40px 0 rgba(85,77,240,0.35)
		-webkit-box-shadow:  0 20px 40px 0 rgba(85,77,240,0.35)
		box-shadow:  0 20px 40px 0 rgba(85,77,240,0.35)
		outline: none

	.formbox .submit button:hover, .formbox .submit button:not(:hover)
		-webkit-transition:  All 0.2s ease
		-moz-transition:  All 0.2s ease
		-o-transition:  All 0.2s ease
		-ms-transition:  All 0.2s ease
		transition:  All 0.2s ease


@media all and (max-width: 1020px)
	html, body
		min-width: 700px

	#nav-w
		width: 80px
		max-width: 1920px
		padding: 0
		margin: 0 0 0 -330px
		position: absolute
		top: 0
		left: 50%
		z-index: 90

	#top h2
		font-family:  'Exo 2', sans-serif
		font-weight: 400
		font-style: normal
		text-align: left
		font-size: 14px
		line-height: 22px
		max-width: 130px
		padding: 0
		margin: 0
		color: #ffffff
		cursor: default


	#top p
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: left
		font-size: 14px
		line-height: 22px
		padding: 0
		margin: 0
		color: rgba(255,255,255,0.5)
		cursor: default
		display: none

	#box-4-w
		flex: 0 0 100%
		padding: 40px 0 0 0
		margin: 0
		position: relative
		text-align: center

	#box-4-w a.logo
		font-family: 'Exo 2', sans-serif
		font-weight: 400
		font-style: normal
		text-align: center
		text-transform: uppercase
		font-size: 16px
		line-height: 24px
		padding: 55px 0 0 0
		margin: 0 auto
		display: inline-block
		color: #ffffff
		cursor: pointer
		position: relative
		background: url('../img/logo-2.png') no-repeat top center

	#box-4
		flex-wrap: wrap
		width: 660px
		padding: 40px 0 50px 0
		margin: 0 auto
		position: relative
		text-align: left
		z-index: 5

	.box-4-l
		order: 2
		flex: 0 0 100%
		padding: 50px 50px 50px 50px
		margin: 0
		position: relative
		text-align: left
		background: url('../img/19.png') repeat-x top center #19202a
		border-top-right-radius: 0px
		-webkit-border-top-right-radius: 0px
		-moz-border-top-right-radius: 0px
		-khtml-border-top-right-radius: 0px
		border-top-left-radius: 0px
		-webkit-border-top-left-radius: 0px
		-moz-border-top-left-radius: 0px
		-khtml-border-top-left-radius: 0px
		border-bottom-right-radius: 10px
		-webkit-border-bottom-right-radius: 10px
		-moz-border-bottom-right-radius: 10px
		-khtml-border-bottom-right-radius: 10px
		border-bottom-left-radius: 10px
		-webkit-border-bottom-left-radius: 10px
		-moz-border-bottom-left-radius: 10px
		-khtml-border-bottom-left-radius: 10px

	.box-4-l h2
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: left
		font-size: 20px
		line-height: 24px
		padding: 0
		margin: 0
		color: #ffffff
		cursor: default

	.box-4-l  p
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: left
		font-size: 14px
		line-height: 24px
		padding: 0
		margin: 5px 0 0 0
		color: rgba(255,255,255,0.5)
		cursor: default

	.box-4-l  h6
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: center
		font-size: 14px
		line-height: 24px
		padding: 0
		margin: 30px auto 0 auto
		color: rgba(255,255,255,1)
		cursor: default

	.box-4-l  h6 a
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: center
		font-size: 14px
		line-height: 14px
		padding: 0
		margin: 0
		border-bottom: 1px solid rgba(255,255,255,1)
		color: rgba(255,255,255,1)
		cursor: pointer
		display: inline-block

	.box-4-l  h6 a:hover
		border-bottom: 1px solid rgba(255,255,255,0)

	.box-4-l .formbox
		width: 100%
		margin: 0
		padding: 0
		text-align: center
		position: relative

	.box-4-l .formbox input
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-size: 16px
		line-height: 24px
		text-indent: 0
		text-align: left
		width: 100%
		height: 24px
		padding: 24px 50px 22px 30px
		border: none
		margin: 20px 0 0 0
		color: #ffffff
		outline: none
		-webkit-appearance:  none
		-moz-appearance: none
		border-radius: 10px
		-webkit-border-radius: 10px
		-moz-border-radius: 10px
		-khtml-border-radius: 10px
		background: #293039

	.box-4-l .formbox .submit
		width: 100%
		height: 70px
		padding: 0
		margin: 20px 0 0 0
		border: none
		outline: none
		position: relative

	.box-4-l .formbox .submit button
		font-family:  'Exo 2', sans-serif
		font-weight: 500
		font-style: normal
		text-align: center
		text-transform: uppercase
		font-size: 14px
		line-height: 72px
		width: 100%
		height: 70px
		padding: 0
		margin: 0
		outline: none
		cursor: pointer
		-webkit-appearance:  none
		-moz-appearance: none
		color: #ffffff
		border: none
		background: #554df0
		border-radius: 10px
		-webkit-border-radius: 10px
		-moz-border-radius: 10px
		-khtml-border-radius: 10px
		-moz-box-shadow:  0 10px 20px 0 rgba(85,77,240,0.25)
		-webkit-box-shadow:  0 10px 20px 0 rgba(85,77,240,0.25)
		box-shadow:  0 10px 20px 0 rgba(85,77,240,0.25)

	.box-4-l .formbox .submit button:hover
		background: #3e35df
		-moz-box-shadow:  0 20px 40px 0 rgba(85,77,240,0.35)
		-webkit-box-shadow:  0 20px 40px 0 rgba(85,77,240,0.35)
		box-shadow:  0 20px 40px 0 rgba(85,77,240,0.35)
		outline: none

	.formbox .submit button:hover, .formbox .submit button:not(:hover)
		-webkit-transition:  All 0.2s ease
		-moz-transition:  All 0.2s ease
		-o-transition:  All 0.2s ease
		-ms-transition:  All 0.2s ease
		transition:  All 0.2s ease

	.box-4-r
		order: 1
		flex: 0 0 100%
		padding: 50px 50px 50px 50px
		margin: 0
		position: relative
		text-align: center
		background: #19202a
		border-top-right-radius: 10px
		-webkit-border-top-right-radius: 10px
		-moz-border-top-right-radius: 10px
		-khtml-border-top-right-radius: 10px
		border-top-left-radius: 10px
		-webkit-border-top-left-radius: 10px
		-moz-border-top-left-radius: 10px
		-khtml-border-top-left-radius: 10px
		border-bottom-right-radius: 0px
		-webkit-border-bottom-right-radius: 0px
		-moz-border-bottom-right-radius: 0px
		-khtml-border-bottom-right-radius: 0px
		border-bottom-left-radius: 0px
		-webkit-border-bottom-left-radius: 0px
		-moz-border-bottom-left-radius: 0px
		-khtml-border-bottom-left-radius: 0px

	.box-4-r img
		width: 100%
		max-width: 377px
		padding: 0
		margin: 0 auto
		display: block
		border-radius: 10px
		-webkit-border-radius: 10px
		-moz-border-radius: 10px
		-khtml-border-radius: 10px
		-moz-box-shadow:  0 30px 35px 0 rgba(0,0,0,0.3)
		-webkit-box-shadow:  0 30px 35px 0 rgba(0,0,0,0.3)
		box-shadow:  0 30px 35px 0 rgba(0,0,0,0.3)

	.box-4-r p
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: center
		font-size: 16px
		line-height: 24px
		padding: 0
		margin: 30px auto 0 auto
		max-width: 290px
		color: rgba(255,255,255,0.35)
		cursor: default


	#box-3-w
		width: 100%
		padding: 0
		margin: 0
		position: relative
		min-height: 770px

	#box-3
		width: 550px
		padding: 30px 0 50px 110px
		margin: 0 auto
		position: relative
		text-align: left
		z-index: 5

	.box-3 h2
		font-family: 'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: left
		font-size: 20px
		line-height: 24px
		min-height: 50px
		padding: 30px 0 0 0
		margin: 30px 0 0 0
		color: #ffffff
		cursor: default

	.box-3-l
		width: calc(100% - 60px)
		padding: 30px 30px 30px 30px
		margin: 30px 0 0 0
		position: relative
		text-align: left
		float: left
		background: #19202a
		border-radius: 10px
		-webkit-border-radius: 10px
		-moz-border-radius: 10px
		-khtml-border-radius: 10px

	.box-3-l h3
		font-family: 'Exo 2', sans-serif
		font-weight: 500
		font-style: normal
		text-align: left
		font-size: 14px
		line-height: 24px
		padding: 0
		margin: 0
		color: #ffffff
		cursor: default

	.p3-1
		font-family: 'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: left
		font-size: 14px
		line-height: 24px
		padding: 0
		margin: 5px 0 0 0
		color: rgba(255,255,255,0.5)
		cursor: default

	.p3-1 br
		display: none

	.p3-2
		font-family: 'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: left
		font-size: 14px
		line-height: 24px
		padding: 0
		margin: 5px 0 0 0
		color: rgba(255,255,255,1)
		cursor: default

	.box-3-l .formbox
		width: 100%
		margin: 30px 0 0 -30px
		padding: 30px 30px 0 30px
		text-align: center
		position: relative
		background: url('../img/19.png') repeat-x top center

	.box-3-l .formbox input
		font-family: 'Exo 2', sans-serif
		font-weight: 100
		font-size: 14px
		line-height: 24px
		text-indent: 0
		text-align: center
		width: calc(50% - 75px)
		height: 24px
		padding: 19px 30px 17px 30px
		border: none
		margin: 0 30px 0 0
		float: left
		color: #ffffff
		outline: none
		-webkit-appearance:  none
		-moz-appearance: none
		border-radius: 10px
		-webkit-border-radius: 10px
		-moz-border-radius: 10px
		-khtml-border-radius: 10px
		background: #293039

	.box-3-l .formbox input:-moz-placeholder
		color: #ffffff

	.box-3-l .formbox input::-webkit-input-placeholder
		color: #ffffff

	.box-3-l .formbox input.error:-moz-placeholder
		color: #f24d4d

	.box-4-l .formbox input.error::-webkit-input-placeholder
		color: #f24d4d

	.box-3-l .formbox input.error
		color: #f24d4d
		border-color: #f24d4d

	input::-webkit-input-placeholder
		opacity:  1
		transition:  opacity 0.3s ease

	input::-moz-placeholder
		opacity:  1
		transition:  opacity 0.3s ease

	input:-moz-placeholder
		opacity:  1
		transition:  opacity 0.3s ease

	input:-ms-input-placeholder
		opacity:  1
		transition:  opacity 0.3s ease

	input:focus::-webkit-input-placeholder
		opacity:  0.3
		transition:  opacity 0.3s ease

	input:focus::-moz-placeholder
		opacity:  0.3
		transition:  opacity 0.3s ease

	input:focus:-moz-placeholder
		opacity:  0.3
		transition:  opacity 0.3s ease

	input:focus:-ms-input-placeholder
		opacity:  0.3
		transition:  opacity 0.3s ease

	.box-3-l .formbox .submit
		width: calc(50% - 15px)
		height: 60px
		padding: 0
		margin: 0
		float: left
		border: none
		outline: none
		position: relative

	.box-3-l .formbox .submit input
		font-family: 'Exo 2', sans-serif
		font-weight: 500
		font-style: normal
		text-align: center
		text-transform: uppercase
		font-size: 14px
		line-height: 62px
		width: 100%
		height: 60px
		padding: 0
		margin: 0
		outline: none
		cursor: pointer
		-webkit-appearance:  none
		-moz-appearance: none
		color: #ffffff
		border: none
		background: #554df0
		border-radius: 10px
		-webkit-border-radius: 10px
		-moz-border-radius: 10px
		-khtml-border-radius: 10px
		-moz-box-shadow:  0 10px 20px 0 rgba(85,77,240,0.25)
		-webkit-box-shadow:  0 10px 20px 0 rgba(85,77,240,0.25)
		box-shadow:  0 10px 20px 0 rgba(85,77,240,0.25)

	.box-3-l .formbox .submit button:hover
		background: #3e35df
		-moz-box-shadow:  0 20px 40px 0 rgba(85,77,240,0.35)
		-webkit-box-shadow:  0 20px 40px 0 rgba(85,77,240,0.35)
		box-shadow:  0 20px 40px 0 rgba(85,77,240,0.35)
		outline: none

	.formbox .submit button:hover, .formbox .submit button:not(:hover)
		-webkit-transition: All 0.2s ease
		-moz-transition: All 0.2s ease
		-o-transition: All 0.2s ease
		-ms-transition: All 0.2s ease
		transition: All 0.2s ease

	.box-3-r
		width: calc(100% - 60px)
		padding: 30px 30px 30px 30px
		margin: 30px 0 0 0
		position: relative
		text-align: left
		float: left
		background: #19202a
		border-radius: 10px
		-webkit-border-radius: 10px
		-moz-border-radius: 10px
		-khtml-border-radius: 10px

	.box-3-r h3
		font-family: 'Exo 2', sans-serif
		font-weight: 500
		font-style: normal
		text-align: left
		font-size: 14px
		line-height: 24px
		padding: 0
		margin: 0
		color: #ffffff
		cursor: default

	.p3-3
		font-family: 'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: left
		font-size: 14px
		line-height: 20px
		min-height: 36px
		padding: 0 0 0 50px
		color: rgba(255,255,255,0.5)
		cursor: default
		position: relative
		width: calc(50% - 50px)
		float: left

	.p3-3-1
		margin: 25px 0 0 0

	.p3-3-2
		margin: 25px 0 0 0

	.p3-3-3
		margin: 25px 0 0 0

	.p3-3-4
		margin: 25px 0 0 0

	.p3-3-5
		margin: 25px 0 0 0

	.p3-3 span
		color: rgba(255,255,255,1)

	.p3-3::after
		content: ''
		width: 36px
		height: 36px
		padding: 0
		margin: 0
		cursor: default
		-moz-box-shadow:  0 10px 20px 0 rgba(85,77,240,0.25)
		-webkit-box-shadow:  0 10px 20px 0 rgba(85,77,240,0.25)
		box-shadow:  0 10px 20px 0 rgba(85,77,240,0.25)
		border-radius: 100%
		-webkit-border-radius: 100%
		-moz-border-radius: 100%
		-khtml-border-radius: 100%
		position: absolute
		top: 2px
		left: 0
		z-index: auto

	.p3-3-1::after
		background: url('../img/22.png') no-repeat center center #554df0

	.p3-3-2::after
		background: url('../img/22.png') no-repeat center center #554df0

	.p3-3-3::after
		background: url('../img/23.png') no-repeat center center #554df0

	.p3-3-4::after
		background: url('../img/24.png') no-repeat center center #554df0

	.p3-3-5::after
		background: url('../img/25.png') no-repeat center center #554df0

	.box-3-b
		width: calc(100% + 30px)
		padding: 0
		margin: 0 0 0 -15px
		position: relative
		text-align: left

	.p3-4
		font-family: 'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: center
		font-size: 14px
		line-height: 24px
		min-height: 36px
		width: calc(50% - 30px)
		padding: 35px 0 35px 0
		margin: 30px 15px 0 15px
		color: rgba(255,255,255,0.5)
		background: #19202a
		border-radius: 10px
		-webkit-border-radius: 10px
		-moz-border-radius: 10px
		-khtml-border-radius: 10px
		cursor: default
		position: relative
		float: left

	.sp3-1
		font-family: 'Exo 2', sans-serif
		font-weight: 500
		font-style: normal
		text-align: center
		text-transform: uppercase
		font-size: 20px
		line-height: 24px
		padding: 0
		margin: 5px 0 0 0
		color: rgba(255,255,255,1)
		display: inline-block

	.sp3-2
		font-family: 'Exo 2', sans-serif
		font-weight: 100

	.sp3-3
		font-family: "digital-7-italic"
		font-weight: normal
		font-style: normal
		text-align: center
		text-transform: uppercase
		font-size: 32px
		line-height: 24px
		padding: 0
		margin: 90px 0 0 0
		color: rgba(255,255,255,1)
		display: inline-block

	.p3-4::after
		content: ''
		width: 100%
		height: 130px
		padding: 0
		margin: 0
		cursor: default
		position: absolute
		left: 0
		bottom: 0
		z-index: auto

	.p3-4-1::after
		background: url('../img/26.png') no-repeat top center

	.p3-4-2::after
		background: url('../img/27.png') no-repeat top center

	.p3-4-3::after
		background: url('../img/28.png') no-repeat top center

	.p3-4-4::after
		background: url('../img/29.png') no-repeat top center


	#box-2-w
		width: 100%
		padding: 0
		margin: 0
		position: relative

	.br960
		display: none

	#box-2
		width: 550px
		padding: 30px 0 50px 110px
		margin: 0 auto
		position: relative
		text-align: left
		z-index: 5

	.box-2-t
		width: calc(100% - 60px)
		padding: 30px 30px 40px 30px
		margin: 30px 0 0 0
		position: relative
		text-align: left
		background: #19202a
		border-top-right-radius: 10px
		-webkit-border-top-right-radius: 10px
		-moz-border-top-right-radius: 10px
		-khtml-border-top-right-radius: 10px
		border-top-left-radius: 10px
		-webkit-border-top-left-radius: 10px
		-moz-border-top-left-radius: 10px
		-khtml-border-top-left-radius: 10px

	.box-2-t h2
		font-family: 'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: left
		font-size: 20px
		line-height: 24px
		padding: 0
		margin: 0 0 10px 0
		color: #ffffff
		cursor: default

	.p2-1
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: left
		font-size: 14px
		line-height: 24px
		padding: 0
		margin: 0
		color: rgba(255,255,255,0.5)
		cursor: default

	.box-2-t h3
		font-family: 'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: left
		font-size: 20px
		line-height: 24px
		padding: 0
		margin: 20px 0 0 0
		color: #ffffff
		cursor: default

	.p2-2
		font-family: 'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: left
		font-size: 14px
		line-height: 24px
		width: calc(100% - 75px)
		padding: 25px 30px 25px 30px
		margin: 40px 0 0 15px
		color: rgba(255,255,255,1)
		border: 1px solid #252b35
		border-radius: 10px
		-webkit-border-radius: 10px
		-moz-border-radius: 10px
		-khtml-border-radius: 10px
		cursor: default
		position: relative

	.p2-2-1
		float: left
		min-height: 10px

	.p2-2-2
		float: right
		min-height: 10px

	.p2-2-3
		float: left
		min-height: 10px

	.p2-2-4
		float: right
		min-height: 10px

	.p2-2 a.appstore
		width: 108px
		height: 33px
		padding: 0
		margin: 5px 0 0 0
		background: url('../img/17.png') no-repeat
		border-radius: 5px
		-webkit-border-radius: 5px
		-moz-border-radius: 5px
		-khtml-border-radius: 5px
		cursor: pointer
		position: relative
		display: inline-block

	.p2-2 a.googleplay
		width: 108px
		height: 33px
		padding: 0
		margin: 5px 0 0 10px
		background: url('../img/18.png') no-repeat
		border-radius: 5px
		-webkit-border-radius: 5px
		-moz-border-radius: 5px
		-khtml-border-radius: 5px
		cursor: pointer
		position: relative
		display: inline-block

	.p2-2 a.appstore:hover,
	.p2-2 a.googleplay:hover
		opacity: 0.5

	.sp2-1
		font-family: 'Exo 2', sans-serif
		font-weight: 500
		font-style: normal
		text-align: center
		font-size: 12px
		line-height: 32px
		width: 30px
		height: 30px
		padding: 0
		margin: 0
		color: rgba(255,255,255,1)
		background: #554df0
		border-radius: 2px
		-webkit-border-radius: 2px
		-moz-border-radius: 2px
		-khtml-border-radius: 2px
		cursor: default
		position: absolute
		top: -15px
		left: -15px
		z-index: auto

	.sp2-2
		font-family: 'Exo 2', sans-serif
		font-weight: 500

	.sp2-3
		font-family: 'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: left
		font-size: 13px
		line-height: 22px
		padding: 0
		margin: 5px 0 0 0
		color: rgba(255,255,255,0.5)
		display: inline-block

	.box-2-b
		width: calc(100% - 60px)
		padding: 40px 30px 0 30px
		margin: 0
		position: relative
		text-align: left
		background: url('../img/19.png') repeat-x top center #19202a
		border-bottom-right-radius: 10px
		-webkit-border-bottom-right-radius: 10px
		-moz-border-bottom-right-radius: 10px
		-khtml-border-bottom-right-radius: 10px
		border-bottom-left-radius: 10px
		-webkit-border-bottom-left-radius: 10px
		-moz-border-bottom-left-radius: 10px
		-khtml-border-bottom-left-radius: 10px

	.box-2-b h2
		font-family: 'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: left
		font-size: 20px
		line-height: 24px
		padding: 0
		margin: 0
		color: #ffffff
		cursor: default

	.box-2-b .formbox
		width: 100%
		margin: 0
		padding: 0
		text-align: center
		position: relative

	.box-2-b .formbox input
		font-family: 'Exo 2', sans-serif
		font-weight: 100
		font-size: 14px
		line-height: 24px
		text-indent: 0
		text-align: center
		width: calc(50% - 75px)
		height: 24px
		padding: 24px 30px 22px 30px
		border: none
		margin: 20px 30px 0 0
		float: left
		color: #ffffff
		outline: none
		-webkit-appearance:  none
		-moz-appearance: none
		border-radius: 10px
		-webkit-border-radius: 10px
		-moz-border-radius: 10px
		-khtml-border-radius: 10px
		background: #293039

	.box-2-b .formbox input:-moz-placeholder
		color: #ffffff

	.box-2-b .formbox input::-webkit-input-placeholder
		color: #ffffff

	.box-2-b .formbox input.error:-moz-placeholder
		color: #f24d4d

	.box-2-b .formbox input.error::-webkit-input-placeholder
		color: #f24d4d

	.box-2-b .formbox input.error
		color: #f24d4d
		border-color: #f24d4d

	input::-webkit-input-placeholder
		opacity: 1
		transition: opacity 0.3s ease

	input::-moz-placeholder
		opacity: 1
		transition: opacity 0.3s ease

	input:-moz-placeholder
		opacity: 1
		transition: opacity 0.3s ease

	input:-ms-input-placeholder
		opacity: 1
		transition: opacity 0.3s ease

	input:focus::-webkit-input-placeholder
		opacity:  0.3
		transition: opacity 0.3s ease

	input:focus::-moz-placeholder
		opacity:  0.3
		transition: opacity 0.3s ease

	input:focus:-moz-placeholder
		opacity:  0.3
		transition: opacity 0.3s ease

	input:focus:-ms-input-placeholder
		opacity:  0.3
		transition: opacity 0.3s ease

	.box-2-b .formbox .submit
		width: calc(50% - 15px)
		height: 70px
		padding: 0
		margin: 20px 0 0 0
		float: left
		border: none
		outline: none
		position: relative

	.box-2-b .formbox .submit button
		font-family: 'Exo 2', sans-serif
		font-weight: 500
		font-style: normal
		text-align: center
		text-transform: uppercase
		font-size: 14px
		line-height: 72px
		width: 100%
		height: 70px
		padding: 0
		margin: 0
		outline: none
		cursor: pointer
		-webkit-appearance:  none
		-moz-appearance: none
		color: #ffffff
		border: none
		background: #554df0
		border-radius: 10px
		-webkit-border-radius: 10px
		-moz-border-radius: 10px
		-khtml-border-radius: 10px
		-moz-box-shadow:  0 10px 20px 0 rgba(85,77,240,0.25)
		-webkit-box-shadow:  0 10px 20px 0 rgba(85,77,240,0.25)
		box-shadow:  0 10px 20px 0 rgba(85,77,240,0.25)

	.box-2-b .formbox .submit button:hover
		background: #3e35df
		-moz-box-shadow:  0 20px 40px 0 rgba(85,77,240,0.35)
		-webkit-box-shadow:  0 20px 40px 0 rgba(85,77,240,0.35)
		box-shadow:  0 20px 40px 0 rgba(85,77,240,0.35)
		outline: none

	.formbox .submit button:hover, .formbox .submit button:not(:hover)
		-webkit-transition: All 0.2s ease
		-moz-transition: All 0.2s ease
		-o-transition: All 0.2s ease
		-ms-transition: All 0.2s ease
		transition: All 0.2s ease

	.p2-3
		font-family: 'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: left
		font-size: 14px
		line-height: 24px
		min-height: 92px
		padding: 80px 0 0 240px
		margin: 30px 0 0 10px
		color: rgba(255,255,255,0.3)
		background: url('../img/20.png') no-repeat 0 0
		cursor: default
		position: relative
		left: auto
		bottom: auto
		z-index: auto

	.p2-3::after
		content: ''
		width: 41px
		height: 44px
		padding: 0
		margin: 0
		background: url('../img/21.png') no-repeat 0 0
		cursor: default
		position: absolute
		top: 25px
		left: 210px
		z-index: auto


	#box-5-w
		width: 100%
		padding: 0
		margin: 0
		position: relative

	#box-5
		width: 550px
		padding: 30px 0 50px 110px
		margin: 0 auto
		position: relative
		text-align: left
		z-index: 5

	.box-5-w
		width: 100%
		padding: 0
		margin: 0
		position: relative
		text-align: left

	.box-5-w h2
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: left
		font-size: 20px
		line-height: 36px
		padding: 0
		margin: 30px 0 0 0
		color: #ffffff
		cursor: default
		float: left

	a.new-transfer-button
		font-family:  'Exo 2', sans-serif
		font-weight: 500
		font-style: normal
		text-align: left
		text-transform: uppercase
		font-size: 13px
		line-height: 36px
		padding: 0 0 0 50px
		margin: 30px 0 0 0
		color: #ffffff
		cursor: pointer
		float: right
		position: relative

	a.new-transfer-button:hover
		color: #554df0

	a.new-transfer-button::after
		content: ''
		width: 36px
		height: 36px
		padding: 0
		margin: 0
		cursor: pointer
		background: url('../img/31.png') no-repeat center center #554df0
		-moz-box-shadow:  0 10px 20px 0 rgba(85,77,240,0.25)
		-webkit-box-shadow:  0 10px 20px 0 rgba(85,77,240,0.25)
		box-shadow:  0 10px 20px 0 rgba(85,77,240,0.25)
		border-radius: 100%
		-webkit-border-radius: 100%
		-moz-border-radius: 100%
		-khtml-border-radius: 100%
		position: absolute
		top: 0
		left: 0
		z-index: auto

	.box-5-nav-w
		width: 100%
		padding: 0
		margin: 30px 0 0 0
		position: relative
		text-align: left

	.box-5-nav
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: center
		font-size: 14px
		line-height: 62px
		height: 60px
		padding: 0
		color: rgba(255,255,255,0.2)
		background: #19202a
		cursor: pointer
		float: left
		border-radius: 10px
		-webkit-border-radius: 10px
		-moz-border-radius: 10px
		-khtml-border-radius: 10px

	.box-5-nav-all
		width: 160px
		margin: 0 30px 0 0

	.box-5-nav-received
		width: 165px
		margin: 0 30px 0 0

	.box-5-nav-sent
		width: 165px
		margin: 0

	.box-5-nav-active
		font-family:  'Exo 2', sans-serif
		font-weight: 300
		color: #262b2e
		background: #ffffff

	.box-5-nav-date
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: center
		font-size: 14px
		line-height: 62px
		height: 60px
		padding: 0
		margin: 30px 0 0 0
		color: rgba(255,255,255,0.2)
		background: #19202a
		cursor: default
		float: right
		border-radius: 10px
		-webkit-border-radius: 10px
		-moz-border-radius: 10px
		-khtml-border-radius: 10px

	.sp-nav-5-1
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: center
		font-size: 14px
		line-height: 62px
		width: 200px
		height: 60px
		padding: 0
		margin: 0
		color: rgba(255,255,255,0.2)
		cursor: default
		float: left

	.sp-nav-5-2
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: center
		font-size: 14px
		line-height: 62px
		width: 174px
		height: 60px
		padding: 0
		margin: 0
		color: rgba(255,255,255,0.2)
		cursor: default
		float: left
		border-left: 1px solid #242a34
		position: relative

	.sp-nav-5-3
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: center
		font-size: 14px
		line-height: 62px
		width: 174px
		height: 60px
		padding: 0
		margin: 0
		color: rgba(255,255,255,0.2)
		cursor: default
		float: left
		border-left: 1px solid #242a34
		position: relative

	.box-5-nav-date input
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-size: 14px
		line-height: 62px
		text-indent: 0
		text-align: left
		width: calc(100% - 60px)
		height: 60px
		padding: 0 20px 0 0
		border: none
		margin: 0 20px
		float: left
		color: rgba(255,255,255,0.2)
		cursor: pointer
		outline: none
		-webkit-appearance:  none
		-moz-appearance: none
		background: url('../img/32.png') no-repeat center right

	.box-5-nav-date input:-moz-placeholder
		color: rgba(255,255,255,0.2)

	.box-5-nav-date input::-webkit-input-placeholder
		color: rgba(255,255,255,0.2)

	.box-5-transaction
		width: 100%
		padding: 0
		margin: 0
		position: relative
		text-align: center

	.box-5
		width: 100%
		min-height: 45px
		padding: 0
		margin: 30px 0 0 0
		position: relative
		text-align: left
		background: url('../img/19.png') repeat-x center 70px #19202a
		border-radius: 10px
		-webkit-border-radius: 10px
		-moz-border-radius: 10px
		-khtml-border-radius: 10px
		float: left

	.box-5:hover
		background: url('../img/19.png') repeat-x center 70px #202936

	.box-5:hover, .box-5:not(:hover)
		-webkit-transition:  All 0.2s ease
		-moz-transition:  All 0.2s ease
		-o-transition:  All 0.2s ease
		-ms-transition:  All 0.2s ease
		transition:  All 0.2s ease

	.box-5 h3
		font-family:  'Exo 2', sans-serif
		font-weight: 500
		font-style: normal
		text-align: left
		font-size: 14px
		line-height: 32px
		height: 30px
		padding: 20px 30px 20px 75px
		margin: 0
		position: relative

	.box-5-received h3
		color: #43a260

	.box-5-sent h3
		color: #f24d4d

	.box-5 h3 span
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: right
		font-size: 14px
		line-height: 32px
		padding: 0
		margin: 0
		color: rgba(255,255,255,0.2)
		float: right
		cursor: default
		position: relative

	.box-5-received h3
		color: #43a260

	.box-5-sent h3
		color: #f24d4d

	.box-5 h3::after
		content: ''
		width: 30px
		height: 30px
		padding: 0
		margin: 0
		position: absolute
		top: 20px
		left: 30px
		z-index: auto
		border-radius: 100%
		-webkit-border-radius: 100%
		-moz-border-radius: 100%
		-khtml-border-radius: 100%

	.box-5-received h3::after
		background: url('../img/6.png') no-repeat center center #43a260
		-moz-box-shadow:  0 10px 20px 0 rgba(67,162,96,0.25)
		-webkit-box-shadow:  0 10px 20px 0 rgba(67,162,96,0.25)
		box-shadow:  0 10px 20px 0 rgba(67,162,96,0.25)

	.box-5-sent h3::after
		background: url('../img/7.png') no-repeat center center #f24d4d
		-moz-box-shadow:  0 10px 20px 0 rgba(272,77,77,0.25)
		-webkit-box-shadow:  0 10px 20px 0 rgba(272,77,77,0.25)
		box-shadow:  0 10px 20px 0 rgba(272,77,77,0.25)

	.p5-1
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: left
		font-size: 14px
		line-height: 20px
		width: calc(50% - 30px)
		padding: 25px 0 25px 30px
		margin: 0
		color: rgba(255,255,255,0.2)
		cursor: default
		float: left

	.p5-1 span
		font-family:  'Exo 2', sans-serif
		font-weight: 500
		font-style: normal
		text-transform: uppercase
		color: rgba(255,255,255,1)
		display: inline-block

	.p5-2
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: left
		font-size: 14px
		line-height: 20px
		width: 50%
		padding: 25px 0 25px 0
		margin: 0
		color: rgba(255,255,255,0.2)
		cursor: default
		float: left

	.p5-2 span
		color: rgba(255,255,255,1)
		display: inline-block

	.p5-3
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: left
		font-size: 14px
		line-height: 20px
		width: calc(100% - 60px)
		padding: 20px 30px 25px 30px
		margin: 0
		color: rgba(255,255,255,0.2)
		cursor: default
		border-top: 1px solid rgba(255,255,255,0.05)

	.p5-3 span
		font-size: 11px
		line-height: 20px
		color: rgba(255,255,255,1)

	.box-5-all
		display: none

	.more-button
		font-family:  'Exo 2', sans-serif
		font-weight: 500
		font-style: normal
		text-align: center
		text-transform: uppercase
		font-size: 13px
		line-height: 24px
		padding: 40px 0 0 0
		margin: 50px auto 0 auto
		display: inline-block
		color: #ffffff
		cursor: pointer
		position: relative
		background: url('../img/30.png') no-repeat top center

	.more-button:hover
		color: #554df0
		background: url('../img/30-2.png') no-repeat top center

	.more-button:hover, .more-button:not(:hover)
		-webkit-transition:  All 0.2s ease
		-moz-transition:  All 0.2s ease
		-o-transition:  All 0.2s ease
		-ms-transition:  All 0.2s ease
		transition:  All 0.2s ease


	#box-1-w
		width: 100%
		padding: 0
		margin: 0
		position: relative

	#box-1
		width: 550px
		padding: 30px 0 50px 110px
		margin: 0 auto
		position: relative
		text-align: left
		z-index: 5

	.box-1-l
		width: 100%
		padding: 0
		margin: 0
		position: relative
		text-align: left
		float: left

	.balance
		width: calc(50% - 15px)
		padding: 0
		margin: 48px 30px 0 0
		position: relative
		text-align: left
		float: left

	.paramining
		width: calc(50% - 15px)
		padding: 0
		margin: 48px 0 0 0
		position: relative
		text-align: left
		float: left

	.box-1-l h2
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: left
		font-size: 14px
		line-height: 24px
		padding: 0
		margin: 0
		color: #ffffff
		cursor: default

	.sp1-1
		font-family:  'Exo 2', sans-serif
		font-weight: 500
		font-style: normal
		text-align: left
		text-transform: uppercase
		font-size: 20px
		line-height: 24px
		padding: 0
		margin: 0
		color: #ffffff
		cursor: default

	.sp1-2
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: left
		text-transform: uppercase
		font-size: 20px
		line-height: 24px
		padding: 0 20px 0 0
		margin: 0
		color: #ffffff
		cursor: default
		position: relative

	.sp1-2::before
		cursor: pointer
		content:  ""
		display:  block
		position:  absolute
		right: 0
		top: 50%
		width: 6px
		height: 6px
		border-top:  1px solid #ffffff
		border-left:  1px solid #ffffff
		-webkit-transform:  translate(-50%,-50%) rotate(-135deg)
		transform:  translate(-50%,-50%) rotate(-135deg)
		transition:  opacity .35s

	.graph
		width: 100%
		height: 278px
		overflow: hidden
		padding: 0
		margin: 40px 0 0 0
		position: relative
		text-align: left
		float: left
		background: url('../img/10.png') repeat 0 0 #19202a
		border-radius: 10px
		-webkit-border-radius: 10px
		-moz-border-radius: 10px
		-khtml-border-radius: 10px

	.graph img
		width: 100%
		max-width: 360px
		padding: 0
		margin: 0
		display: block

	.box-1-r
		width: 100%
		padding: 0
		margin: 30px 0 0 0
		position: relative
		text-align: left
		float: right

	.box-1-r h2
		font-family:  'Exo 2', sans-serif
		font-weight: 400
		font-style: normal
		text-align: left
		font-size: 18px
		line-height: 24px
		padding: 20px 0 0 0
		margin: 0
		color: #ffffff
		cursor: default

	.box-1-r .transaction
		width: calc(100% - 95px)
		min-height: 45px
		padding: 20px 20px 15px 75px
		margin: 30px 0 0 0
		position: relative
		text-align: left
		background: #19202a
		border-radius: 10px
		-webkit-border-radius: 10px
		-moz-border-radius: 10px
		-khtml-border-radius: 10px

	.box-1-r a.transaction-button
		font-family:  'Exo 2', sans-serif
		font-weight: 500
		font-style: normal
		text-align: center
		font-size: 14px
		line-height: 62px
		width: 100%
		height: 60px
		padding: 0
		margin: 30px 0 0 0
		cursor: pointer
		position: relative
		color: #151b22
		background: #ffffff
		border-radius: 10px
		-webkit-border-radius: 10px
		-moz-border-radius: 10px
		-khtml-border-radius: 10px
		display: block

	.box-1-r a.transaction-button:hover
		color: rgba(21,27,34,0.8)
		-moz-box-shadow:  0 15px 25px 0 rgba(0,0,0,0.35)
		-webkit-box-shadow:  0 15px 25px 0 rgba(0,0,0,0.35)
		box-shadow:  0 15px 25px 0 rgba(0,0,0,0.35)

	.box-1-r .transaction:hover
		background: #202936

	.transaction:hover, .transaction:not(:hover)
		-webkit-transition:  All 0.2s ease
		-moz-transition:  All 0.2s ease
		-o-transition:  All 0.2s ease
		-ms-transition:  All 0.2s ease
		transition:  All 0.2s ease

	.box-1-r .transaction::after
		content: ''
		width: 40px
		height: 40px
		padding: 0
		margin: 0
		position: absolute
		top: 20px
		left: 20px
		z-index: auto
		border-radius: 100%
		-webkit-border-radius: 100%
		-moz-border-radius: 100%

	.box-1-r .transaction-received::after
		background: url('../img/6.png') no-repeat center center #43a260
		-moz-box-shadow:  0 10px 20px 0 rgba(67,162,96,0.25)
		-webkit-box-shadow:  0 10px 20px 0 rgba(67,162,96,0.25)
		box-shadow:  0 10px 20px 0 rgba(67,162,96,0.25)

	.box-1-r .transaction-sent::after
		background: url('../img/7.png') no-repeat center center #f24d4d
		-moz-box-shadow:  0 10px 20px 0 rgba(272,77,77,0.25)
		-webkit-box-shadow:  0 10px 20px 0 rgba(272,77,77,0.25)
		box-shadow:  0 10px 20px 0 rgba(272,77,77,0.25)

	.box-1-r .transaction h3
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: left
		font-size: 12px
		line-height: 20px
		padding: 0
		margin: 0
		color: rgba(255,255,255,0.5)
		cursor: default
		float: left

	.box-1-r .transaction h3 span
		font-family:  'Exo 2', sans-serif
		font-weight: 500
		font-style: normal
		text-align: left
		font-size: 14px
		line-height: 20px
		padding: 0
		margin: 0
		color: rgba(255,255,255,1)
		cursor: default

	.box-1-r .transaction p
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: right
		font-size: 12px
		line-height: 20px
		padding: 0
		margin: 0
		color: rgba(255,255,255,0.5)
		cursor: default
		float: right

	.box-1-r .transaction p span
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: right
		font-size: 14px
		line-height: 20px
		padding: 0
		margin: 0
		color: rgba(255,255,255,1)
		cursor: default

	.box-1-r .structure
		width: calc(100% - 40px)
		min-height: 45px
		padding: 20px 20px 30px 20px
		margin: 30px 0 0 0
		position: relative
		text-align: center
		background: #19202a
		border-radius: 10px
		-webkit-border-radius: 10px
		-moz-border-radius: 10px

	.box-1-r .structure::after
		content: ''
		width: 100%
		height: 100px
		padding: 0
		margin: 0
		position: absolute
		left: 0
		bottom: 30px
		z-index: auto
		background: url('../img/9.png') no-repeat bottom center

	.box-1-r .structure h3
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: center
		font-size: 14px
		line-height: 24px
		padding: 0
		margin: 0
		color: rgba(255,255,255,1)
		cursor: default

	.box-1-r .structure .str-p-1
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: center
		text-transform: uppercase
		font-size: 20px
		line-height: 24px
		padding: 0
		margin: 30px 0 0 0
		color: rgba(255,255,255,1)
		cursor: default

	.box-1-r .structure .str-p-1 span
		font-family:  'Exo 2', sans-serif
		font-weight: 500

	.box-1-r .structure .str-p-2
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: center
		text-transform: uppercase
		font-size: 14px
		line-height: 24px
		padding: 0
		margin: 0
		color: rgba(255,255,255,1)
		cursor: default

	.box-1-r .structure .str-p-2 span
		font-family:  'Exo 2', sans-serif
		font-weight: 500

	.box-1-r .structure .str-p-3
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: center
		font-size: 12px
		line-height: 18px
		padding: 35px 0 0 0
		margin: 65px 0 0 0
		background: url('../img/8.png') no-repeat top center
		color: rgba(255,255,255,0.5)
		cursor: default

	.box-1-r .structure .str-p-3 span
		font-size: 16px
		line-height: 18px

	.box-1-b
		width: 100%
		padding: 0
		margin: 30px 0 0 0
		position: relative
		text-align: left
		float: left

	.box-1-b a
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: center
		font-size: 14px
		line-height: 52px
		width: 50%
		height: 52px
		padding: 0
		margin: 0
		color: rgba(255,255,255,0.2)
		border-bottom: 3px solid #171e26
		background: #171e26
		cursor: pointer
		float: left
		border-top-right-radius: 10px
		-webkit-border-top-right-radius: 10px
		-moz-border-top-right-radius: 10px
		-khtml-border-top-right-radius: 10px
		border-top-left-radius: 10px
		-webkit-border-top-left-radius: 10px
		-moz-border-top-left-radius: 10px
		-khtml-border-top-left-radius: 10px

	.box-1-b a.active
		color: rgba(255,255,255,1)
		border-bottom: 3px solid #554df0
		background: #19202a

	.box-1-b p
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: left
		font-size: 16px
		line-height: 24px
		padding: 0
		width: calc(50% - 30px)
		margin: 53px 15px 0 15px
		color: rgba(255,255,255,1)
		cursor: default
		float: right

	.box-1-b p span
		font-family:  'Exo 2', sans-serif
		font-weight: 500

	.box-1-b .formbox
		width: calc(100% - 30px)
		margin: 0
		padding: 0 15px 30px 15px
		text-align: center
		position: relative
		border-top: 1px solid #2d3239
		background: #19202a
		border-bottom-right-radius: 10px
		-webkit-border-bottom-right-radius: 10px
		-moz-border-bottom-right-radius: 10px
		-khtml-border-bottom-right-radius: 10px
		border-bottom-left-radius: 10px
		-webkit-border-bottom-left-radius: 10px
		-moz-border-bottom-left-radius: 10px
		-khtml-border-bottom-left-radius: 10px

	.box-1-b .formbox input
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-size: 14px
		line-height: 24px
		text-indent: 0
		text-align: center
		width: calc(50% - 90px)
		height: 24px
		padding: 24px 30px 22px 30px
		border: none
		margin: 30px 15px 0 15px
		float: left
		color: #ffffff
		outline: none
		-webkit-appearance:  none
		-moz-appearance: none
		border-radius: 10px
		-webkit-border-radius: 10px
		-moz-border-radius: 10px
		-khtml-border-radius: 10px
		background: #293039

	.box-1-b .formbox input:-moz-placeholder
		color: #ffffff

	.box-1-b .formbox input::-webkit-input-placeholder
		color: #ffffff

	.box-1-b .formbox input.error:-moz-placeholder
		color: #f24d4d

	.box-1-b .formbox input.error::-webkit-input-placeholder
		color: #f24d4d

	.box-1-b .formbox input.error
		color: #f24d4d
		border-color: #f24d4d

	input::-webkit-input-placeholder
		opacity:  1
		transition: opacity 0.3s ease

	input::-moz-placeholder
		opacity:  1
		transition: opacity 0.3s ease

	input:-moz-placeholder
		opacity:  1
		transition: opacity 0.3s ease

	input:-ms-input-placeholder
		opacity:  1
		transition: opacity 0.3s ease

	input:focus::-webkit-input-placeholder
		opacity:  0.3
		transition: opacity 0.3s ease

	input:focus::-moz-placeholder
		opacity:  0.3
		transition: opacity 0.3s ease

	input:focus:-moz-placeholder
		opacity:  0.3
		transition: opacity 0.3s ease

	input:focus:-ms-input-placeholder
		opacity:  0.3
		transition: opacity 0.3s ease


	.box-1-b .formbox .submit
		width: calc(50% - 30px)
		height: 70px
		padding: 0
		margin: 30px 15px 0 15px
		float: left
		border: none
		outline: none
		position: relative

	.box-1-b .formbox .submit button
		font-family:  'Exo 2', sans-serif
		font-weight: 500
		font-style: normal
		text-align: center
		text-transform: uppercase
		font-size: 14px
		line-height: 72px
		width: 100%
		height: 70px
		padding: 0
		margin: 0
		outline: none
		cursor: pointer
		-webkit-appearance:  none
		-moz-appearance: none
		color: #ffffff
		border: none
		background: #554df0
		border-radius: 10px
		-webkit-border-radius: 10px
		-moz-border-radius: 10px
		-khtml-border-radius: 10px
		-moz-box-shadow:  0 10px 20px 0 rgba(85,77,240,0.25)
		-webkit-box-shadow:  0 10px 20px 0 rgba(85,77,240,0.25)
		box-shadow:  0 10px 20px 0 rgba(85,77,240,0.25)

	.box-1-b .formbox .submit button:hover
		background: #3e35df
		-moz-box-shadow:  0 20px 40px 0 rgba(85,77,240,0.35)
		-webkit-box-shadow:  0 20px 40px 0 rgba(85,77,240,0.35)
		box-shadow:  0 20px 40px 0 rgba(85,77,240,0.35)
		outline: none

	.formbox .submit button:hover, .formbox .submit button:not(:hover)
		-webkit-transition:  All 0.2s ease
		-moz-transition:  All 0.2s ease
		-o-transition:  All 0.2s ease
		-ms-transition:  All 0.2s ease
		transition:  All 0.2s ease

	.show-more-btn .show-more
		width: 100%


/* Medium Devices, Desktops */
@media only screen and (max-width : 992px)
	.delegator-address
		font-size: 20px


/* Small Devices, Tablets */
@media only screen and (max-width : 768px)
	/**/


@media all and (min-width:  721px)
	#mobile-nav-w
		display: none
		width: 0
		height: 0
		overflow: hidden
		padding: 0
		margin: 0
		background: rgba(21,27,34,1)
		position: absolute
		top: 90px
		left: 0
		z-index: 90
		-moz-box-shadow:  0 25px 20px 0 rgba(0,0,0,0)
		-webkit-box-shadow:  0 25px 20px 0 rgba(0,0,0,0)
		box-shadow:  0 25px 20px 0 rgba(0,0,0,0)


@media all and (max-width: 720px)
	html, body
		min-width: 400px

	#nav-w
		width: 70px
		max-width: 1920px
		padding: 0
		margin: 0 0 0 110px
		position: absolute
		top: 20px
		left: 50%
		z-index: 90

	#nav
		width: 70px
		padding: 0
		margin: 0 auto
		position: relative
		text-align: center
		z-index: auto

	#nav a
		width: 70px
		height: 70px
		overflow: hidden
		padding: 0
		margin: 0
		cursor: pointer
		position: relative
		background: #19202a
		float: left
		border-radius: 10px
		-webkit-border-radius: 10px
		-moz-border-radius: 10px
		-khtml-border-radius: 10px

	#nav a::after
		content: ''
		width: 100%
		height: 100%
		padding: 0
		margin: 0
		cursor: pointer
		position: absolute
		top: 0
		left: 0
		z-index: auto
		opacity: 0.3
		border-radius: 10px
		-webkit-border-radius: 10px
		-moz-border-radius: 10px
		-khtml-border-radius: 10px

	#nav a.logo::after
		background: url('../img/logo.png') no-repeat center center #554df0
		opacity: 1

	#nav a.nav-1::after
		background: url('../img/1.png') no-repeat center center

	#nav a.nav-2::after
		background: url('../img/2.png') no-repeat center center

	#nav a.nav-3::after
		background: url('../img/3.png') no-repeat center center

	#nav a.nav-4::after
		background: url('../img/4.png') no-repeat center center

	#nav a.nav-5::after
		background: url('../img/46.png') no-repeat center center

	#nav a.nav-6::after
		background: url('../img/validator.png') no-repeat center center

	#nav a.logo
		display: none

	#nav a.nav-2
		display: none

	#nav a.nav-3
		display: none

	#nav a.nav-4
		display: none

	#nav a.nav-5
		display: none

	#nav a.nav-6
		display: none

	#nav a.nav-1:hover::after
		background: url('../img/1.png') no-repeat center center #554df0

	#nav a.nav-2:hover::after
		background: url('../img/2.png') no-repeat center center #554df0

	#nav a.nav-3:hover::after
		background: url('../img/3.png') no-repeat center center #554df0

	#nav a.nav-4:hover::after
		background: url('../img/4.png') no-repeat center center #554df0

	#nav a.nav-5:hover::after
		background: url('../img/4.png') no-repeat center center #554df0

	#nav a.nav-6:hover::after
		background: url('../img/validator.png') no-repeat center center #554df0

	#nav a.active-nav::after
		opacity: 1

	#nav a:hover::after
		opacity: 1

	.opennav
		display: block
		width: 70px
		height: 70px
		padding: 0
		margin: 0 0 0 -180px
		position: absolute
		top: 20px
		left: 50%
		z-index: 90
		cursor: pointer
		background: #554df0
		border-radius: 10px
		-webkit-border-radius: 10px
		-moz-border-radius: 10px

	.opennav:hover
		background: #3e35df

	.opennav:hover, .opennav:not(:hover)
		-webkit-transition:  All 0.2s ease
		-moz-transition:  All 0.2s ease
		-o-transition:  All 0.2s ease
		-ms-transition:  All 0.2s ease
		transition:  All 0.2s ease

	.opennav::after
		content: ''
		position: absolute
		width: 20px
		height: 1px
		background: #ffffff
		top: 27px
		left: 23px
		box-shadow:  0 16px 0 #ffffff

	.opennav::before
		content: ''
		position: absolute
		width: 25px
		height: 1px
		background: none
		top: 27px
		left: 23px
		box-shadow:  0 8px 0 #ffffff

	#top-w
		width: 100%
		padding: 0
		margin: 0
		position: relative

	#top
		width: calc(100% - 200px)
		min-height: 45px
		padding: 15px 100px 10px 100px
		margin: 0
		position: relative
		text-align: left
		z-index: 5
		background: none
		border-radius: 10px
		-webkit-border-radius: 10px
		-moz-border-radius: 10px
		-khtml-border-radius: 10px

	#top a.log-out
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: left
		font-size: 14px
		line-height: 24px
		padding: 0 30px 0 0
		margin: 0
		color: #ffffff
		cursor: pointer
		display: none
		background: url('../img/5.png') no-repeat center right
		position: absolute
		top: 30px
		right: 30px
		z-index: auto

	#top a.log-out:hover
		color: #554df0
		background: url('../img/5-2.png') no-repeat center right

	#top h2
		font-family:  'Exo 2', sans-serif
		font-weight: 400
		font-style: normal
		text-align: center
		font-size: 14px
		line-height: 22px
		max-width: 130px
		padding: 0
		margin: 0 auto
		color: #ffffff
		cursor: default

	#top p
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: left
		font-size: 14px
		line-height: 22px
		padding: 0
		margin: 0
		color: rgba(255,255,255,0.5)
		cursor: default
		display: none

	#box-4-w
		width: 100%
		padding: 30px 0 0 0
		margin: 0
		position: relative
		text-align: center

	#box-4-w a.logo
		font-family:  'Exo 2', sans-serif
		font-weight: 400
		font-style: normal
		text-align: center
		text-transform: uppercase
		font-size: 16px
		line-height: 24px
		padding: 55px 0 0 0
		margin: 0 auto
		display: inline-block
		color: #ffffff
		cursor: pointer
		position: relative
		background: url('../img/logo-2.png') no-repeat top center

	#box-4
		width: 360px
		padding: 30px 0 50px 0
		margin: 0 auto
		position: relative
		text-align: left
		z-index: 5

	.box-4-l
		flex: 0 0 100%
		padding: 30px 30px 30px 30px
		margin: 0
		position: relative
		text-align: left
		background: url('../img/19.png') repeat-x top center #19202a
		border-top-right-radius: 0px
		-webkit-border-top-right-radius: 0px
		-moz-border-top-right-radius: 0px
		-khtml-border-top-right-radius: 0px
		border-top-left-radius: 0px
		-webkit-border-top-left-radius: 0px
		-moz-border-top-left-radius: 0px
		-khtml-border-top-left-radius: 0px
		border-bottom-right-radius: 10px
		-webkit-border-bottom-right-radius: 10px
		-moz-border-bottom-right-radius: 10px
		-khtml-border-bottom-right-radius: 10px
		border-bottom-left-radius: 10px
		-webkit-border-bottom-left-radius: 10px
		-moz-border-bottom-left-radius: 10px
		-khtml-border-bottom-left-radius: 10px

	.box-4-l h2
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: left
		font-size: 20px
		line-height: 24px
		padding: 0
		margin: 0
		color: #ffffff
		cursor: default

	.box-4-l  p
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: left
		font-size: 14px
		line-height: 24px
		padding: 0
		margin: 5px 0 0 0
		color: rgba(255,255,255,0.5)
		cursor: default

	.box-4-l  h6
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: center
		font-size: 14px
		line-height: 24px
		padding: 0
		margin: 20px auto 0 auto
		color: rgba(255,255,255,1)
		cursor: default

	.box-4-l  h6 a
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: center
		font-size: 14px
		line-height: 14px
		padding: 0
		margin: 0
		border-bottom: 1px solid rgba(255,255,255,1)
		color: rgba(255,255,255,1)
		cursor: pointer
		display: inline-block

	.box-4-l  h6 a:hover
		border-bottom: 1px solid rgba(255,255,255,0)

	.box-4-l .formbox
		width: 100%
		margin: 0
		padding: 0
		text-align: center
		position: relative

	.box-4-l .formbox input
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-size: 16px
		line-height: 24px
		text-indent: 0
		text-align: left
		width: 100%
		height: 24px
		padding: 24px 55px 22px 25px
		border: none
		margin: 20px 0 0 0
		color: #ffffff
		outline: none
		-webkit-appearance:  none
		-moz-appearance: none
		border-radius: 10px
		-webkit-border-radius: 10px
		-moz-border-radius: 10px
		-khtml-border-radius: 10px
		background: #293039

	.box-4-l .formbox .submit
		width: 100%
		height: 70px
		padding: 0
		margin: 20px 0 0 0
		border: none
		outline: none
		position: relative

	.box-4-l .formbox .submit button
		font-family:  'Exo 2', sans-serif
		font-weight: 500
		font-style: normal
		text-align: center
		text-transform: uppercase
		font-size: 14px
		line-height: 72px
		width: 100%
		height: 70px
		padding: 0
		margin: 0
		outline: none
		cursor: pointer
		-webkit-appearance:  none
		-moz-appearance: none
		color: #ffffff
		border: none
		background: #554df0
		border-radius: 10px
		-webkit-border-radius: 10px
		-moz-border-radius: 10px
		-khtml-border-radius: 10px
		-moz-box-shadow:  0 10px 20px 0 rgba(85,77,240,0.25)
		-webkit-box-shadow:  0 10px 20px 0 rgba(85,77,240,0.25)
		box-shadow:  0 10px 20px 0 rgba(85,77,240,0.25)

	.box-4-l .formbox .submit button:hover
		background: #3e35df
		-moz-box-shadow:  0 20px 40px 0 rgba(85,77,240,0.35)
		-webkit-box-shadow:  0 20px 40px 0 rgba(85,77,240,0.35)
		box-shadow:  0 20px 40px 0 rgba(85,77,240,0.35)
		outline: none

	.formbox .submit button:hover, .formbox .submit button:not(:hover)
		-webkit-transition:  All 0.2s ease
		-moz-transition:  All 0.2s ease
		-o-transition:  All 0.2s ease
		-ms-transition:  All 0.2s ease
		transition:  All 0.2s ease

	.box-4-r
		flex: 0 0 100%
		padding: 30px 30px 30px 30px
		margin: 0
		position: relative
		text-align: center
		background: #19202a
		border-top-right-radius: 10px
		-webkit-border-top-right-radius: 10px
		-moz-border-top-right-radius: 10px
		-khtml-border-top-right-radius: 10px
		border-top-left-radius: 10px
		-webkit-border-top-left-radius: 10px
		-moz-border-top-left-radius: 10px
		-khtml-border-top-left-radius: 10px
		border-bottom-right-radius: 0px
		-webkit-border-bottom-right-radius: 0px
		-moz-border-bottom-right-radius: 0px
		-khtml-border-bottom-right-radius: 0px
		border-bottom-left-radius: 0px
		-webkit-border-bottom-left-radius: 0px
		-moz-border-bottom-left-radius: 0px
		-khtml-border-bottom-left-radius: 0px

	.box-4-r img
		width: 100%
		max-width: 377px
		padding: 0
		margin: 0 auto
		display: block
		border-radius: 10px
		-webkit-border-radius: 10px
		-moz-border-radius: 10px
		-khtml-border-radius: 10px
		-moz-box-shadow:  0 30px 35px 0 rgba(0,0,0,0.3)
		-webkit-box-shadow:  0 30px 35px 0 rgba(0,0,0,0.3)
		box-shadow:  0 30px 35px 0 rgba(0,0,0,0.3)

	.box-4-r p
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: center
		font-size: 14px
		line-height: 24px
		padding: 0
		margin: 20px auto 0 auto
		max-width: 250px
		color: rgba(255,255,255,0.35)
		cursor: default


	#box-3-w
		width: 100%
		padding: 0
		margin: 0
		position: relative
		min-height: 770px

	#box-3
		width: 360px
		padding: 20px 0 50px 0
		margin: 0 auto
		position: relative
		text-align: left
		z-index: 5

	.box-3 h2
		font-family: 'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: left
		font-size: 20px
		line-height: 24px
		min-height: 10px
		padding: 0
		margin: 40px 0 0 0
		color: #ffffff
		cursor: default

	.box-3-l
		width: calc(100% - 60px)
		padding: 30px 30px 30px 30px
		margin: 30px 0 0 0
		position: relative
		text-align: left
		float: left
		background: #19202a
		border-radius: 10px
		-webkit-border-radius: 10px
		-moz-border-radius: 10px
		-khtml-border-radius: 10px

	.box-3-l h3
		font-family: 'Exo 2', sans-serif
		font-weight: 500
		font-style: normal
		text-align: left
		font-size: 14px
		line-height: 24px
		padding: 0
		margin: 0
		color: #ffffff
		cursor: default

	.p3-1
		font-family: 'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: left
		font-size: 14px
		line-height: 24px
		padding: 0
		margin: 5px 0 0 0
		color: rgba(255,255,255,0.5)
		cursor: default

	.p3-1 br
		display: none

	.p3-2
		font-family: 'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: left
		font-size: 14px
		line-height: 24px
		padding: 0
		margin: 5px 0 0 0
		color: rgba(255,255,255,1)
		cursor: default

	.box-3-l .formbox
		width: 100%
		margin: 30px 0 0 -30px
		padding: 30px 30px 0 30px
		text-align: center
		position: relative
		background: url('../img/19.png') repeat-x top center

	.box-3-l .formbox input
		font-family: 'Exo 2', sans-serif
		font-weight: 100
		font-size: 14px
		line-height: 24px
		text-indent: 0
		text-align: center
		width: calc(100% - 60px)
		height: 24px
		padding: 19px 30px 17px 30px
		border: none
		margin: 0
		float: left
		color: #ffffff
		outline: none
		-webkit-appearance:  none
		-moz-appearance: none
		border-radius: 10px
		-webkit-border-radius: 10px
		-moz-border-radius: 10px
		-khtml-border-radius: 10px
		background: #293039

	.box-3-l .formbox input:-moz-placeholder
		color: #ffffff

	.box-3-l .formbox input::-webkit-input-placeholder
		color: #ffffff

	.box-3-l .formbox input.error:-moz-placeholder
		color: #f24d4d

	.box-3-l .formbox input.error::-webkit-input-placeholder
		color: #f24d4d

	.box-3-l .formbox input.error
		color: #f24d4d
		border-color: #f24d4d

	input::-webkit-input-placeholder
		opacity:  1
		transition: opacity 0.3s ease

	input::-moz-placeholder
		opacity:  1
		transition: opacity 0.3s ease

	input:-moz-placeholder
		opacity:  1
		transition: opacity 0.3s ease

	input:-ms-input-placeholder
		opacity:  1
		transition: opacity 0.3s ease

	input:focus::-webkit-input-placeholder
		opacity:  0.3
		transition: opacity 0.3s ease

	input:focus::-moz-placeholder
		opacity:  0.3
		transition: opacity 0.3s ease

	input:focus:-moz-placeholder
		opacity:  0.3
		transition: opacity 0.3s ease

	input:focus:-ms-input-placeholder
		opacity:  0.3
		transition: opacity 0.3s ease

	.box-3-l .formbox .submit
		width: 100%
		height: 60px
		padding: 0
		margin: 20px 0 0 0
		float: left
		border: none
		outline: none
		position: relative

	.box-3-l .formbox .submit button
		font-family:  'Exo 2', sans-serif
		font-weight: 500
		font-style: normal
		text-align: center
		text-transform: uppercase
		font-size: 14px
		line-height: 62px
		width: 100%
		height: 60px
		padding: 0
		margin: 0
		outline: none
		cursor: pointer
		-webkit-appearance:  none
		-moz-appearance: none
		color: #ffffff
		border: none
		background: #554df0
		border-radius: 10px
		-webkit-border-radius: 10px
		-moz-border-radius: 10px
		-khtml-border-radius: 10px
		-moz-box-shadow:  0 10px 20px 0 rgba(85,77,240,0.25)
		-webkit-box-shadow:  0 10px 20px 0 rgba(85,77,240,0.25)
		box-shadow:  0 10px 20px 0 rgba(85,77,240,0.25)

	.box-3-l .formbox .submit button:hover
		background: #3e35df
		-moz-box-shadow:  0 20px 40px 0 rgba(85,77,240,0.35)
		-webkit-box-shadow:  0 20px 40px 0 rgba(85,77,240,0.35)
		box-shadow:  0 20px 40px 0 rgba(85,77,240,0.35)
		outline: none

	.formbox .submit button:hover, .formbox .submit button:not(:hover)
		-webkit-transition:  All 0.2s ease
		-moz-transition:  All 0.2s ease
		-o-transition:  All 0.2s ease
		-ms-transition:  All 0.2s ease
		transition:  All 0.2s ease

	.box-3-r
		width: calc(100% - 60px)
		padding: 30px 30px 30px 30px
		margin: 30px 0 0 0
		position: relative
		text-align: left
		float: left
		background: #19202a
		border-radius: 10px
		-webkit-border-radius: 10px
		-moz-border-radius: 10px
		-khtml-border-radius: 10px

	.box-3-r h3
		font-family: 'Exo 2', sans-serif
		font-weight: 500
		font-style: normal
		text-align: left
		font-size: 14px
		line-height: 24px
		padding: 0
		margin: 0
		color: #ffffff
		cursor: default

	.p3-3
		font-family: 'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: left
		font-size: 14px
		line-height: 20px
		min-height: 36px
		padding: 0 0 0 50px
		color: rgba(255,255,255,0.5)
		cursor: default
		position: relative
		width: calc(100% - 50px)
		float: left

	.p3-3-1
		margin: 20px 0 0 0

	.p3-3-2
		margin: 15px 0 0 0

	.p3-3-3
		margin: 15px 0 0 0

	.p3-3-4
		margin: 15px 0 0 0

	.p3-3-5
		margin: 15px 0 0 0

	.p3-3 span
		color: rgba(255,255,255,1)

	.p3-3::after
		content: ''
		width: 36px
		height: 36px
		padding: 0
		margin: 0
		cursor: default
		-moz-box-shadow:  0 10px 20px 0 rgba(85,77,240,0.25)
		-webkit-box-shadow:  0 10px 20px 0 rgba(85,77,240,0.25)
		box-shadow:  0 10px 20px 0 rgba(85,77,240,0.25)
		border-radius: 100%
		-webkit-border-radius: 100%
		-moz-border-radius: 100%
		-khtml-border-radius: 100%
		position: absolute
		top: 2px
		left: 0
		z-index: auto

	.p3-3-1::after
		background: url('../img/22.png') no-repeat center center #554df0

	.p3-3-2::after
		background: url('../img/22.png') no-repeat center center #554df0

	.p3-3-3::after
		background: url('../img/23.png') no-repeat center center #554df0

	.p3-3-4::after
		background: url('../img/24.png') no-repeat center center #554df0

	.p3-3-5::after
		background: url('../img/25.png') no-repeat center center #554df0

	.box-3-b
		width: 100%
		padding: 0
		margin: 0
		position: relative
		text-align: left

	.p3-4
		font-family: 'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: center
		font-size: 14px
		line-height: 24px
		min-height: 36px
		width: 100%
		padding: 35px 0 35px 0
		margin: 30px 0 0 0
		color: rgba(255,255,255,0.5)
		background: #19202a
		border-radius: 10px
		-webkit-border-radius: 10px
		-moz-border-radius: 10px
		-khtml-border-radius: 10px
		cursor: default
		position: relative
		float: left

	.sp3-1
		font-family: 'Exo 2', sans-serif
		font-weight: 500
		font-style: normal
		text-align: center
		text-transform: uppercase
		font-size: 20px
		line-height: 24px
		padding: 0
		margin: 5px 0 0 0
		color: rgba(255,255,255,1)
		display: inline-block

	.sp3-2
		font-family: 'Exo 2', sans-serif
		font-weight: 100

	.sp3-3
		font-family: "digital-7-italic"
		font-weight: normal
		font-style: normal
		text-align: center
		text-transform: uppercase
		font-size: 32px
		line-height: 24px
		padding: 0
		margin: 90px 0 0 0
		color: rgba(255,255,255,1)
		display: inline-block

	.p3-4::after
		content: ''
		width: 100%
		height: 130px
		padding: 0
		margin: 0
		cursor: default
		position: absolute
		left: 0
		bottom: 0
		z-index: auto

	.p3-4-1::after
		background: url('../img/26.png') no-repeat top center

	.p3-4-2::after
		background: url('../img/27.png') no-repeat top center

	.p3-4-3::after
		background: url('../img/28.png') no-repeat top center

	.p3-4-4::after
		background: url('../img/29.png') no-repeat top center


	#box-2-w
		width: 100%
		padding: 0
		margin: 0
		position: relative

	.br960
		display: none

	#box-2
		width: 360px
		padding: 20px 0 50px 0
		margin: 0 auto
		position: relative
		text-align: left
		z-index: 5

	.box-2-t
		width: calc(100% - 60px)
		padding: 30px 30px 40px 30px
		margin: 30px 0 0 0
		position: relative
		text-align: left
		background: #19202a
		border-top-right-radius: 10px
		-webkit-border-top-right-radius: 10px
		-moz-border-top-right-radius: 10px
		-khtml-border-top-right-radius: 10px
		border-top-left-radius: 10px
		-webkit-border-top-left-radius: 10px
		-moz-border-top-left-radius: 10px
		-khtml-border-top-left-radius: 10px

	.box-2-t h2
		font-family: 'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: left
		font-size: 18px
		line-height: 24px
		padding: 0
		margin: 0 0 10px 0
		color: #ffffff
		cursor: default

	.p2-1
		font-family: 'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: left
		font-size: 14px
		line-height: 24px
		padding: 0
		margin: 0
		color: rgba(255,255,255,0.5)
		cursor: default

	.box-2-t h3
		font-family: 'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: left
		font-size: 18px
		line-height: 24px
		padding: 0
		margin: 20px 0 0 0
		color: #ffffff
		cursor: default

	.p2-2
		font-family: 'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: left
		font-size: 14px
		line-height: 24px
		width: calc(100% - 60px)
		padding: 20px 20px 20px 25px
		margin: 40px 0 0 15px
		color: rgba(255,255,255,1)
		border: 1px solid #252b35
		border-radius: 10px
		-webkit-border-radius: 10px
		-moz-border-radius: 10px
		-khtml-border-radius: 10px
		cursor: default
		position: relative

	.p2-2-1
		float: left
		min-height: 10px

	.p2-2-2
		float: right
		min-height: 10px

	.p2-2-3
		float: left
		min-height: 10px

	.p2-2-4
		float: right
		min-height: 10px

	.p2-2 a.appstore
		width: 108px
		height: 33px
		padding: 0
		margin: 5px 0 0 0
		background: url('../img/17.png') no-repeat
		border-radius: 5px
		-webkit-border-radius: 5px
		-moz-border-radius: 5px
		-khtml-border-radius: 5px
		cursor: pointer
		position: relative
		display: inline-block

	.p2-2 a.googleplay
		width: 108px
		height: 33px
		padding: 0
		margin: 5px 0 0 10px
		background: url('../img/18.png') no-repeat
		border-radius: 5px
		-webkit-border-radius: 5px
		-moz-border-radius: 5px
		-khtml-border-radius: 5px
		cursor: pointer
		position: relative
		display: inline-block

	.p2-2 a.appstore:hover,
	.p2-2 a.googleplay:hover
		opacity: 0.5

	.sp2-1
		font-family: 'Exo 2', sans-serif
		font-weight: 500
		font-style: normal
		text-align: center
		font-size: 12px
		line-height: 32px
		width: 30px
		height: 30px
		padding: 0
		margin: 0
		color: rgba(255,255,255,1)
		background: #554df0
		border-radius: 2px
		-webkit-border-radius: 2px
		-moz-border-radius: 2px
		-khtml-border-radius: 2px
		cursor: default
		position: absolute
		top: -15px
		left: -15px
		z-index: auto

	.sp2-2
		font-family: 'Exo 2', sans-serif
		font-weight: 500

	.sp2-3
		font-family: 'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: left
		font-size: 13px
		line-height: 22px
		padding: 0
		margin: 5px 0 0 0
		color: rgba(255,255,255,0.5)
		display: inline-block

	.box-2-b
		width: calc(100% - 60px)
		padding: 40px 30px 0 30px
		margin: 0
		position: relative
		text-align: left
		background: url('../img/19.png') repeat-x top center #19202a
		border-bottom-right-radius: 10px
		-webkit-border-bottom-right-radius: 10px
		-moz-border-bottom-right-radius: 10px
		-khtml-border-bottom-right-radius: 10px
		border-bottom-left-radius: 10px
		-webkit-border-bottom-left-radius: 10px
		-moz-border-bottom-left-radius: 10px
		-khtml-border-bottom-left-radius: 10px

	.box-2-b h2
		font-family: 'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: left
		font-size: 18px
		line-height: 24px
		padding: 0
		margin: 0
		color: #ffffff
		cursor: default

	.box-2-b .formbox
		width: 100%
		margin: 0
		padding: 0
		text-align: center
		position: relative

	.box-2-b .formbox input
		font-family: 'Exo 2', sans-serif
		font-weight: 100
		font-size: 14px
		line-height: 24px
		text-indent: 0
		text-align: center
		width: calc(100% - 60px)
		height: 24px
		padding: 24px 30px 22px 30px
		border: none
		margin: 20px 0 0 0
		float: left
		color: #ffffff
		outline: none
		-webkit-appearance:  none
		-moz-appearance: none
		border-radius: 10px
		-webkit-border-radius: 10px
		-moz-border-radius: 10px
		-khtml-border-radius: 10px
		background: #293039

	.box-2-b .formbox input:-moz-placeholder
		color: #ffffff

	.box-2-b .formbox input::-webkit-input-placeholder
		color: #ffffff

	.box-2-b .formbox input.error:-moz-placeholder
		color: #f24d4d

	.box-2-b .formbox input.error::-webkit-input-placeholder
		color: #f24d4d

	.box-2-b .formbox input.error
		color: #f24d4d
		border-color: #f24d4d

	input::-webkit-input-placeholder
		opacity:  1
		transition: opacity 0.3s ease

	input::-moz-placeholder
		opacity:  1
		transition: opacity 0.3s ease

	input:-moz-placeholder
		opacity:  1
		transition: opacity 0.3s ease

	input:-ms-input-placeholder
		opacity:  1
		transition: opacity 0.3s ease

	input:focus::-webkit-input-placeholder
		opacity:  0.3
		transition: opacity 0.3s ease

	input:focus::-moz-placeholder
		opacity:  0.3
		transition: opacity 0.3s ease

	input:focus:-moz-placeholder
		opacity:  0.3
		transition: opacity 0.3s ease

	input:focus:-ms-input-placeholder
		opacity:  0.3
		transition: opacity 0.3s ease


	.box-2-b .formbox .submit
		width: 100%
		height: 70px
		padding: 0
		margin: 20px 0 0 0
		float: left
		border: none
		outline: none
		position: relative

	.box-2-b .formbox .submit button
		font-family:  'Exo 2', sans-serif
		font-weight: 500
		font-style: normal
		text-align: center
		text-transform: uppercase
		font-size: 14px
		line-height: 72px
		width: 100%
		height: 70px
		padding: 0
		margin: 0
		outline: none
		cursor: pointer
		-webkit-appearance:  none
		-moz-appearance: none
		color: #ffffff
		border: none
		background: #554df0
		border-radius: 10px
		-webkit-border-radius: 10px
		-moz-border-radius: 10px
		-khtml-border-radius: 10px
		-moz-box-shadow:  0 10px 20px 0 rgba(85,77,240,0.25)
		-webkit-box-shadow:  0 10px 20px 0 rgba(85,77,240,0.25)
		box-shadow:  0 10px 20px 0 rgba(85,77,240,0.25)

	.box-2-b .formbox .submit button:hover
		background: #3e35df
		-moz-box-shadow:  0 20px 40px 0 rgba(85,77,240,0.35)
		-webkit-box-shadow:  0 20px 40px 0 rgba(85,77,240,0.35)
		box-shadow:  0 20px 40px 0 rgba(85,77,240,0.35)
		outline: none

	.formbox .submit button:hover, .formbox .submit button:not(:hover)
		-webkit-transition: All 0.2s ease
		-moz-transition: All 0.2s ease
		-o-transition: All 0.2s ease
		-ms-transition: All 0.2s ease
		transition: All 0.2s ease

	.p2-3
		font-family: 'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: center
		font-size: 14px
		line-height: 24px
		min-height: 10px
		padding: 0 0 190px 0
		margin: 40px 0 0 0
		color: rgba(255,255,255,0.3)
		background: url('../img/20.png') no-repeat bottom center
		cursor: default
		position: relative
		left: auto
		bottom: auto
		z-index: auto

	.p2-3::after
		content: ''
		width: 41px
		height: 44px
		padding: 0
		margin: 0
		background: url('../img/21.png') no-repeat 0 0
		cursor: default
		position: absolute
		top: 25px
		left: 210px
		z-index: auto
		display: none


	#box-5-w
		width: 100%
		padding: 0
		margin: 0
		position: relative

	#box-5
		width: 360px
		padding: 20px 0 50px 0
		margin: 0 auto
		position: relative
		text-align: left
		z-index: 5

	.box-5-w
		width: 100%
		padding: 0
		margin: 0
		position: relative
		text-align: left

	.box-5-w h2
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: left
		font-size: 20px
		line-height: 36px
		padding: 0
		margin: 35px 0 0 0
		color: #ffffff
		cursor: default
		float: left

	a.new-transfer-button
		font-family:  'Exo 2', sans-serif
		font-weight: 500
		font-style: normal
		text-align: left
		text-transform: uppercase
		font-size: 0
		line-height: 0
		padding: 0 0 0 36px
		margin: 35px 0 0 0
		color: #ffffff
		cursor: pointer
		float: right
		position: relative

	a.new-transfer-button:hover
		color: #554df0

	a.new-transfer-button::after
		content: ''
		width: 36px
		height: 36px
		padding: 0
		margin: 0
		cursor: pointer
		background: url('../img/31.png') no-repeat center center #554df0
		-moz-box-shadow:  0 10px 20px 0 rgba(85,77,240,0.25)
		-webkit-box-shadow:  0 10px 20px 0 rgba(85,77,240,0.25)
		box-shadow:  0 10px 20px 0 rgba(85,77,240,0.25)
		border-radius: 100%
		-webkit-border-radius: 100%
		-moz-border-radius: 100%
		-khtml-border-radius: 100%
		position: absolute
		top: 0
		left: 0
		z-index: auto

	.box-5-nav-w
		width: 100%
		padding: 0
		margin: 30px 0 0 0
		position: relative
		text-align: left

	.box-5-nav
		font-family:  'Exo 2', sans-serif
		font-weight: 400
		font-style: normal
		text-align: center
		font-size: 14px
		line-height: 62px
		height: 60px
		padding: 0
		color: rgba(255,255,255,0.2)
		background: #19202a
		cursor: pointer
		float: left
		border-radius: 10px
		-webkit-border-radius: 10px
		-moz-border-radius: 10px
		-khtml-border-radius: 10px

	.box-5-nav-all span
		display: none

	.box-5-nav-all
		width: calc(33.33% - 10px)
		margin: 0

	.box-5-nav-received
		width: calc(33.33% - 10px)
		margin: 0 15px

	.box-5-nav-sent
		width: calc(33.33% - 10px)
		margin: 0

	.box-5-nav-active
		font-family:  'Exo 2', sans-serif
		font-weight: 400
		color: #262b2e
		background: #ffffff

	.box-5-nav-date
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: center
		font-size: 14px
		line-height: 62px
		height: 60px
		padding: 0
		margin: 15px 0 0 0
		color: rgba(255,255,255,0.2)
		background: #19202a
		cursor: default
		float: right
		border-radius: 10px
		-webkit-border-radius: 10px
		-moz-border-radius: 10px
		-khtml-border-radius: 10px

	.sp-nav-5-1
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: center
		font-size: 14px
		line-height: 62px
		width: 200px
		height: 60px
		padding: 0
		margin: 0
		color: rgba(255,255,255,0.2)
		cursor: default
		float: left
		display: none

	.sp-nav-5-2
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: center
		font-size: 14px
		line-height: 62px
		width: 180px
		height: 60px
		padding: 0
		margin: 0
		color: rgba(255,255,255,0.2)
		cursor: default
		float: left
		border-left: none
		position: relative

	.sp-nav-5-3
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: center
		font-size: 14px
		line-height: 62px
		width: 179px
		height: 60px
		padding: 0
		margin: 0
		color: rgba(255,255,255,0.2)
		cursor: default
		float: left
		border-left: 1px solid #242a34
		position: relative

	.box-5-nav-date input
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-size: 14px
		line-height: 62px
		text-indent: 0
		text-align: left
		width: calc(100% - 60px)
		height: 60px
		padding: 0 20px 0 0
		border: none
		margin: 0 20px
		float: left
		color: rgba(255,255,255,0.2)
		cursor: pointer
		outline: none
		-webkit-appearance:  none
		-moz-appearance: none
		background: url('../img/32.png') no-repeat center right

	.box-5-nav-date input:-moz-placeholder
		color: rgba(255,255,255,0.2)

	.box-5-nav-date input::-webkit-input-placeholder
		color: rgba(255,255,255,0.2)

	.box-5-transaction
		width: 100%
		padding: 0
		margin: 0
		position: relative
		text-align: center

	.box-5
		width: 100%
		min-height: 45px
		padding: 0
		margin: 30px 0 0 0
		position: relative
		text-align: left
		background: url('../img/19.png') repeat-x center 70px #19202a
		border-radius: 10px
		-webkit-border-radius: 10px
		-moz-border-radius: 10px
		-khtml-border-radius: 10px
		float: left

	.box-5:hover
		background: url('../img/19.png') repeat-x center 70px #202936

	.box-5:hover, .box-5:not(:hover)
		-webkit-transition:  All 0.2s ease
		-moz-transition:  All 0.2s ease
		-o-transition:  All 0.2s ease
		-ms-transition:  All 0.2s ease
		transition:  All 0.2s ease

	.box-5 h3
		font-family:  'Exo 2', sans-serif
		font-weight: 500
		font-style: normal
		text-align: left
		font-size: 14px
		line-height: 32px
		height: 30px
		padding: 20px 30px 20px 75px
		margin: 0
		position: relative

	.box-5-received h3
		color: #43a260

	.box-5-sent h3
		color: #f24d4d

	.box-5 h3 span
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: right
		font-size: 14px
		line-height: 32px
		padding: 0
		margin: 0
		color: rgba(255,255,255,0.2)
		float: right
		cursor: default
		position: relative

	.box-5-received h3
		color: #43a260

	.box-5-sent h3
		color: #f24d4d

	.box-5 h3::after
		content: ''
		width: 30px
		height: 30px
		padding: 0
		margin: 0
		position: absolute
		top: 20px
		left: 30px
		z-index: auto
		border-radius: 100%
		-webkit-border-radius: 100%
		-moz-border-radius: 100%
		-khtml-border-radius: 100%

	.box-5-received h3::after
		background: url('../img/6.png') no-repeat center center #43a260
		-moz-box-shadow:  0 10px 20px 0 rgba(67,162,96,0.25)
		-webkit-box-shadow:  0 10px 20px 0 rgba(67,162,96,0.25)
		box-shadow:  0 10px 20px 0 rgba(67,162,96,0.25)

	.box-5-sent h3::after
		background: url('../img/7.png') no-repeat center center #f24d4d
		-moz-box-shadow:  0 10px 20px 0 rgba(272,77,77,0.25)
		-webkit-box-shadow:  0 10px 20px 0 rgba(272,77,77,0.25)
		box-shadow:  0 10px 20px 0 rgba(272,77,77,0.25)

	.p5-1
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: left
		font-size: 14px
		line-height: 20px
		width: calc(50% - 30px)
		padding: 25px 0 25px 30px
		margin: 0
		color: rgba(255,255,255,0.2)
		cursor: default
		float: left

	.p5-1 span
		font-family:  'Exo 2', sans-serif
		font-weight: 500
		font-style: normal
		text-transform: uppercase
		color: rgba(255,255,255,1)
		display: inline-block

	.p5-2
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: left
		font-size: 14px
		line-height: 20px
		width: 50%
		padding: 25px 0 25px 0
		margin: 0
		color: rgba(255,255,255,0.2)
		cursor: default
		float: left

	.p5-2 span
		color: rgba(255,255,255,1)
		display: inline-block

	.p5-3
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: left
		font-size: 14px
		line-height: 20px
		width: calc(100% - 60px)
		padding: 20px 30px 25px 30px
		margin: 0
		color: rgba(255,255,255,0.2)
		cursor: default
		border-top: 1px solid rgba(255,255,255,0.05)

	.p5-3 span
		font-size: 11px
		line-height: 20px
		color: rgba(255,255,255,1)

	.box-5-all
		display: none


	.more-button
		font-family:  'Exo 2', sans-serif
		font-weight: 500
		font-style: normal
		text-align: center
		text-transform: uppercase
		font-size: 13px
		line-height: 24px
		padding: 40px 0 0 0
		margin: 50px auto 0 auto
		display: inline-block
		color: #ffffff
		cursor: pointer
		position: relative
		background: url('../img/30.png') no-repeat top center

	.more-button:hover
		color: #554df0
		background: url('../img/30-2.png') no-repeat top center

	.more-button:hover, .more-button:not(:hover)
		-webkit-transition:  All 0.2s ease
		-moz-transition:  All 0.2s ease
		-o-transition:  All 0.2s ease
		-ms-transition:  All 0.2s ease
		transition:  All 0.2s ease


	#box-1-w
		width: 100%
		padding: 0
		margin: 0
		position: relative

	#box-1
		width: 360px
		padding: 20px 0 50px 0
		margin: 0 auto
		position: relative
		text-align: left
		z-index: 5

	.box-1-l
		width: 100%
		padding: 0
		margin: 0
		position: relative
		text-align: left
		float: left

	.balance
		width: 100%
		padding: 0
		margin: 35px 0 0 0
		position: relative
		text-align: left
		float: left

	.paramining
		width: 100%
		padding: 0
		margin: 30px 0 0 0
		position: relative
		text-align: left
		float: left

	.box-1-l h2
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: left
		font-size: 14px
		line-height: 24px
		padding: 0
		margin: 0
		color: #ffffff
		cursor: default

	.sp1-1
		font-family:  'Exo 2', sans-serif
		font-weight: 500
		font-style: normal
		text-align: left
		text-transform: uppercase
		font-size: 20px
		line-height: 24px
		padding: 0
		margin: 0
		color: #ffffff
		cursor: default

	.sp1-2
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: left
		text-transform: uppercase
		font-size: 20px
		line-height: 24px
		padding: 0 20px 0 0
		margin: 0
		color: #ffffff
		cursor: default
		position: relative

	.sp1-2::before
		cursor: pointer
		content:  ""
		display:  block
		position:  absolute
		right: 0
		top: 50%
		width: 6px
		height: 6px
		border-top:  1px solid #ffffff
		border-left:  1px solid #ffffff
		-webkit-transform:  translate(-50%,-50%) rotate(-135deg)
		transform:  translate(-50%,-50%) rotate(-135deg)
		transition:  opacity .35s

	.graph
		width: 100%
		height: 260px
		overflow: hidden
		padding: 0
		margin: 15px 0 0 0
		position: relative
		text-align: left
		float: left
		background: url('../img/10.png') repeat 0 0 #19202a
		border-radius: 10px
		-webkit-border-radius: 10px
		-moz-border-radius: 10px
		-khtml-border-radius: 10px

	.graph img
		width: 100%
		max-width: 360px
		padding: 0
		margin: 0
		display: block

	.box-1-r
		width: 100%
		padding: 0
		margin: 20px 0 0 0
		position: relative
		text-align: left
		float: right

	.box-1-r h2
		font-family:  'Exo 2', sans-serif
		font-weight: 400
		font-style: normal
		text-align: left
		font-size: 18px
		line-height: 24px
		padding: 20px 0 0 0
		margin: 0
		color: #ffffff
		cursor: default

	.box-1-r .transaction
		width: calc(100% - 95px)
		min-height: 45px
		padding: 20px 20px 15px 75px
		margin: 20px 0 0 0
		position: relative
		text-align: left
		background: #19202a
		border-radius: 10px
		-webkit-border-radius: 10px
		-moz-border-radius: 10px
		-khtml-border-radius: 10px

	.box-1-r a.transaction-button
		font-family:  'Exo 2', sans-serif
		font-weight: 500
		font-style: normal
		text-align: center
		font-size: 14px
		line-height: 62px
		width: 100%
		height: 60px
		padding: 0
		margin: 30px 0 0 0
		cursor: pointer
		position: relative
		color: #151b22
		background: #ffffff
		border-radius: 10px
		-webkit-border-radius: 10px
		-moz-border-radius: 10px
		-khtml-border-radius: 10px
		display: block

	.box-1-r a.transaction-button:hover
		color: rgba(21,27,34,0.8)
		-moz-box-shadow:  0 15px 25px 0 rgba(0,0,0,0.35)
		-webkit-box-shadow:  0 15px 25px 0 rgba(0,0,0,0.35)
		box-shadow:  0 15px 25px 0 rgba(0,0,0,0.35)

	.box-1-r .transaction:hover
		background: #202936

	.transaction:hover, .transaction:not(:hover)
		-webkit-transition:  All 0.2s ease
		-moz-transition:  All 0.2s ease
		-o-transition:  All 0.2s ease
		-ms-transition:  All 0.2s ease
		transition:  All 0.2s ease

	.box-1-r .transaction::after
		content: ''
		width: 40px
		height: 40px
		padding: 0
		margin: 0
		position: absolute
		top: 20px
		left: 20px
		z-index: auto
		border-radius: 100%
		-webkit-border-radius: 100%
		-moz-border-radius: 100%

	.box-1-r .transaction-received::after
		background: url('../img/6.png') no-repeat center center #43a260
		-moz-box-shadow:  0 10px 20px 0 rgba(67,162,96,0.25)
		-webkit-box-shadow:  0 10px 20px 0 rgba(67,162,96,0.25)
		box-shadow:  0 10px 20px 0 rgba(67,162,96,0.25)

	.box-1-r .transaction-sent::after
		background: url('../img/7.png') no-repeat center center #f24d4d
		-moz-box-shadow:  0 10px 20px 0 rgba(272,77,77,0.25)
		-webkit-box-shadow:  0 10px 20px 0 rgba(272,77,77,0.25)
		box-shadow:  0 10px 20px 0 rgba(272,77,77,0.25)

	.box-1-r .transaction h3
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: left
		font-size: 12px
		line-height: 20px
		padding: 0
		margin: 0
		color: rgba(255,255,255,0.5)
		cursor: default
		float: left

	.box-1-r .transaction h3 span
		font-family:  'Exo 2', sans-serif
		font-weight: 500
		font-style: normal
		text-align: left
		font-size: 14px
		line-height: 20px
		padding: 0
		margin: 0
		color: rgba(255,255,255,1)
		cursor: default

	.box-1-r .transaction p
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: right
		font-size: 12px
		line-height: 20px
		padding: 0
		margin: 0
		color: rgba(255,255,255,0.5)
		cursor: default
		float: right

	.box-1-r .transaction p span
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: right
		font-size: 14px
		line-height: 20px
		padding: 0
		margin: 0
		color: rgba(255,255,255,1)
		cursor: default

	.box-1-r .structure
		width: calc(100% - 40px)
		min-height: 45px
		padding: 20px 20px 30px 20px
		margin: 35px 0 0 0
		position: relative
		text-align: center
		background: #19202a
		border-radius: 10px
		-webkit-border-radius: 10px
		-moz-border-radius: 10px

	.box-1-r .structure::after
		content: ''
		width: 100%
		height: 100px
		padding: 0
		margin: 0
		position: absolute
		left: 0
		bottom: 30px
		z-index: auto
		background: url('../img/9.png') no-repeat bottom center

	.box-1-r .structure h3
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: center
		font-size: 14px
		line-height: 24px
		padding: 0
		margin: 0
		color: rgba(255,255,255,1)
		cursor: default

	.box-1-r .structure .str-p-1
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: center
		text-transform: uppercase
		font-size: 20px
		line-height: 24px
		padding: 0
		margin: 30px 0 0 0
		color: rgba(255,255,255,1)
		cursor: default

	.box-1-r .structure .str-p-1 span
		font-family:  'Exo 2', sans-serif
		font-weight: 500

	.box-1-r .structure .str-p-2
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: center
		text-transform: uppercase
		font-size: 14px
		line-height: 24px
		padding: 0
		margin: 0
		color: rgba(255,255,255,1)
		cursor: default

	.box-1-r .structure .str-p-2 span
		font-family:  'Exo 2', sans-serif
		font-weight: 500

	.box-1-r .structure .str-p-3
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: center
		font-size: 12px
		line-height: 18px
		padding: 35px 0 0 0
		margin: 65px 0 0 0
		background: url('../img/8.png') no-repeat top center
		color: rgba(255,255,255,0.5)
		cursor: default

	.box-1-r .structure .str-p-3 span
		font-size: 16px
		line-height: 18px

	.box-1-b
		width: 100%
		padding: 0
		margin: 30px 0 0 0
		position: relative
		text-align: left
		float: left

	.box-1-b a
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: center
		font-size: 14px
		line-height: 52px
		width: 50%
		height: 52px
		padding: 0
		margin: 0
		color: rgba(255,255,255,0.2)
		border-bottom: 3px solid #171e26
		background: #171e26
		cursor: pointer
		float: left
		border-top-right-radius: 10px
		-webkit-border-top-right-radius: 10px
		-moz-border-top-right-radius: 10px
		-khtml-border-top-right-radius: 10px
		border-top-left-radius: 10px
		-webkit-border-top-left-radius: 10px
		-moz-border-top-left-radius: 10px
		-khtml-border-top-left-radius: 10px

	.box-1-b a.active
		color: rgba(255,255,255,1)
		border-bottom: 3px solid #554df0
		background: #19202a

	.box-1-b p
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-style: normal
		text-align: left
		font-size: 16px
		line-height: 24px
		padding: 0
		width: 100%
		margin: 20px 0 0 0
		color: rgba(255,255,255,1)
		cursor: default
		float: right

	.box-1-b p span
		font-family:  'Exo 2', sans-serif
		font-weight: 500

	.box-1-b .formbox
		width: calc(100% - 60px)
		margin: 0
		padding: 10px 30px 30px 30px
		text-align: center
		position: relative
		border-top: 1px solid #2d3239
		background: #19202a
		border-bottom-right-radius: 10px
		-webkit-border-bottom-right-radius: 10px
		-moz-border-bottom-right-radius: 10px
		-khtml-border-bottom-right-radius: 10px
		border-bottom-left-radius: 10px
		-webkit-border-bottom-left-radius: 10px
		-moz-border-bottom-left-radius: 10px
		-khtml-border-bottom-left-radius: 10px

	.box-1-b .formbox input
		font-family:  'Exo 2', sans-serif
		font-weight: 100
		font-size: 14px
		line-height: 24px
		text-indent: 0
		text-align: center
		width: calc(100% - 60px)
		height: 24px
		padding: 24px 30px 22px 30px
		border: none
		margin: 20px 0 0 0
		float: left
		color: #ffffff
		outline: none
		-webkit-appearance:  none
		-moz-appearance: none
		border-radius: 10px
		-webkit-border-radius: 10px
		-moz-border-radius: 10px
		-khtml-border-radius: 10px
		background: #293039

	.box-1-b .formbox input:-moz-placeholder
		color: #ffffff

	.box-1-b .formbox input::-webkit-input-placeholder
		color: #ffffff

	.box-1-b .formbox input.error:-moz-placeholder
		color: #f24d4d

	.box-1-b .formbox input.error::-webkit-input-placeholder
		color: #f24d4d

	.box-1-b .formbox input.error
		color: #f24d4d
		border-color: #f24d4d

	input::-webkit-input-placeholder
		opacity:  1
		transition: opacity 0.3s ease

	input::-moz-placeholder
		opacity:  1
		transition: opacity 0.3s ease

	input:-moz-placeholder
		opacity:  1
		transition: opacity 0.3s ease

	input:-ms-input-placeholder
		opacity:  1
		transition: opacity 0.3s ease

	input:focus::-webkit-input-placeholder
		opacity: 0.3
		transition: opacity 0.3s ease

	input:focus::-moz-placeholder
		opacity: 0.3
		transition: opacity 0.3s ease

	input:focus:-moz-placeholder
		opacity: 0.3
		transition: opacity 0.3s ease

	input:focus:-ms-input-placeholder
		opacity: 0.3
		transition: opacity 0.3s ease

	.box-1-b .formbox .submit
		width: 100%
		height: 70px
		padding: 0
		margin: 20px 0 0 0
		float: left
		border: none
		outline: none
		position: relative

	.box-1-b .formbox .submit button
		font-family:  'Exo 2', sans-serif
		font-weight: 500
		font-style: normal
		text-align: center
		text-transform: uppercase
		font-size: 14px
		line-height: 72px
		width: 100%
		height: 70px
		padding: 0
		margin: 0
		outline: none
		cursor: pointer
		-webkit-appearance:  none
		-moz-appearance: none
		color: #ffffff
		border: none
		background: #554df0
		border-radius: 10px
		-webkit-border-radius: 10px
		-moz-border-radius: 10px
		-khtml-border-radius: 10px
		-moz-box-shadow:  0 10px 20px 0 rgba(85,77,240,0.25)
		-webkit-box-shadow:  0 10px 20px 0 rgba(85,77,240,0.25)
		box-shadow:  0 10px 20px 0 rgba(85,77,240,0.25)

	.box-1-b .formbox .submit button:hover
		background: #3e35df
		-moz-box-shadow:  0 20px 40px 0 rgba(85,77,240,0.35)
		-webkit-box-shadow:  0 20px 40px 0 rgba(85,77,240,0.35)
		box-shadow:  0 20px 40px 0 rgba(85,77,240,0.35)
		outline: none

	.formbox .submit button:hover, .formbox .submit button:not(:hover)
		-webkit-transition:  All 0.2s ease
		-moz-transition:  All 0.2s ease
		-o-transition:  All 0.2s ease
		-ms-transition:  All 0.2s ease
		transition:  All 0.2s ease


/* Extra Small Devices, Phones */
@media only screen and (max-width : 576px)
	.delegator-address
		font-size: 15px


/* Extra Small Devices, Phones */
@media only screen and (max-width : 480px)
	.account-login-type-btns-wrap
		.btn-group
			flex-wrap: wrap
		.account-login-type-btn
			width: 100%
			border-radius: 4px
			border-top-right-radius: 4px !important
			border-bottom-right-radius: 4px !important
			border-bottom-left-radius: 4px !important
			border-top-left-radius: 4px !important

	.delegator-address
		font-size: 12px

	.delegators-btns-controls
		flex-wrap: wrap
		button, a
			display: block
			margin: 0 !important
			width: 100%
			flex: 0 0 100%
			&:not(:last-child)
				margin-bottom: 10px !important
