.validator-header {
  color: #181818;
}

.validator-get-reward-btn {
  margin: 20px 0;
}

.validator-modal {
  text-align: center;
  color: #181818;
}

@media (max-width: 480px) {
  .validator-get-reward-btn {
    width: 100%;

    button {
      flex: auto !important;
    }
  }
}
