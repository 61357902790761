img {
  width: 14;
  height: 14;
}
.choose-lang {
  width: 40px;
  padding: 5px 25px 5px 10px;
  margin: 0;
  position: absolute;
  top: 32px;
  right: 0;
  background: #554df0;
  z-index: 92;

  text-align: left;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -khtml-border-radius: 5px;
  cursor: pointer;

  &::after {
    content: "";
    position: absolute;
    right: 10px;
    top: 17px;
    padding: 0;
    margin: 0;
    border: 4px solid transparent;
    border-top: 4px solid #ffffff;
    z-index: auto;
    cursor: pointer;
  }

  a {
    font-family: "Exo 2", sans-serif;
    font-weight: 400;
    font-style: normal;
    text-align: left;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 14px;
    height: 14px;
    padding: 5px 0 5px 5px;
    margin: 0;
    color: #ffffff;
    cursor: pointer;
    background: #554df0;
    position: relative;
    display: none;
    opacity: 0.85;

    &.lang-active {
      display: block;
    }
  }

  a:hover {
    opacity: 1;
    color: #ffffff;
  }

  &:hover {
    a {
      text-decoration: none;
      display: block;
    }

    -webkit-transition: All 0.2s ease;
    -moz-transition: All 0.2s ease;
    -o-transition: All 0.2s ease;
    -ms-transition: All 0.2s ease;
    transition: All 0.2s ease;
  }

  &:not(:hover) {
    -webkit-transition: All 0.2s ease;
    -moz-transition: All 0.2s ease;
    -o-transition: All 0.2s ease;
    -ms-transition: All 0.2s ease;
    transition: All 0.2s ease;
  }
}
#box-4 {
  z-index: 1;
  padding: 0px 0 20px !important;
}
#page {
  .box-4-l,
  .box-4-r {
    flex-shrink: 1 !important;
  }

  @media screen and (max-width: 1024px) {
    #box-4 {
      width: 100% !important;
    }
  }
  @media screen and (max-width: 760px) {
    #box-4-1-1 a {
      font-size: 12px;
    }
    .box-4-l,
    .box-4-r {
      flex-shrink: 1 !important;
    }
  }
}

#box-4-1-1 {
  margin: 20px 0;
  display: flex;
  justify-content: space-between;

  a {
    font-family: "Exo 2", sans-serif;
    font-weight: 500;
    font-style: normal;
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 48px;
    width: 45%;
    height: 48px;
    padding: 0;
    margin: 0;
    outline: none;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    color: #ffffff !important;
    border: none;
    background: #554df0;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -khtml-border-radius: 10px;
    -moz-box-shadow: 0 10px 20px 0 rgba(85, 77, 240, 0.25);
    -webkit-box-shadow: 0 10px 20px 0 rgba(85, 77, 240, 0.25);
    box-shadow: 0 10px 20px 0 rgba(85, 77, 240, 0.25);
  }
}

.mnemo-btns {
  margin-top: 20px;
  width: 100%;
}

.mnemo-btn {
  font-family: "Exo 2", sans-serif;
  font-weight: 500;
  font-style: normal;
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 72px;
  width: 100%;
  height: 70px;
  padding: 0;
  margin: 0 0 20px 0 !important;
  outline: none;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  color: #ffffff;
  border: none;
  background: #554df0;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -khtml-border-radius: 10px;
  -moz-box-shadow: 0 10px 20px 0 rgba(85, 77, 240, 0.25);
  -webkit-box-shadow: 0 10px 20px 0 rgba(85, 77, 240, 0.25);
  box-shadow: 0 10px 20px 0 rgba(85, 77, 240, 0.25);
  box-sizing: border-box !important;
}

.mnemo-btn-lg {
  line-height: 72px;
  width: 100% !important;
  height: 70px;
  padding: 0;
  margin: 10px 0 10px 0 !important;
  box-sizing: border-box !important;
}

.btn-bg {
  line-height: 48px;
  width: 100% !important;
  height: 48px;
  padding: 0;
  margin: 10px 0 10px 0 !important;
  box-sizing: border-box !important;
}
.lang {
  margin: 0 auto;
  width: 76px;
  display: flex;
  border-radius: 5px;
  border: 0.5px solid gray;
  justify-content: center;
  align-items: center;
  &__block {
    display: flex;
    padding: 5px;
    align-items: center;
    cursor: pointer;
    img {
      margin-right: 7px;
    }
  }
  i {
    margin-left: 7px;
  }
}
#form-registration input {
  width: 100% !important;
  box-sizing: border-box;
  height: 70px;
}
